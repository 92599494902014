.react-modal-overlay.user-modal-overlay {
  padding: 3.6em 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(160, 160, 160, 0.5333333333);
  backdrop-filter: blur(5px);
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal {
  max-width: 480px;
  padding: 40px 20px;
  border-radius: 5px;
  background-color: #FFFFFF;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content {
  width: 100%;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options {
  width: 100%;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .user-information {
  width: 100%;
  height: 3.5em;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .user-information .icon-container.user-icon-container {
  width: 3.5em;
  margin-right: 20px;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .user-information .icon-container.user-icon-container .icon-md-account-circle {
  width: 100%;
  height: 100%;
  color: #1A051D;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .user-information .email-and-phone-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .user-information .email-and-phone-container .user-email-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .user-information .email-and-phone-container .user-email-container .icon-container {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .user-information .email-and-phone-container .user-email-container .icon-container .icon-md-email {
  color: #1A051D;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .user-information .email-and-phone-container .user-email-container .user-email {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  color: #3F3356;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .user-information .email-and-phone-container .user-phone-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .user-information .email-and-phone-container .user-phone-container .icon-container {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .user-information .email-and-phone-container .user-phone-container .icon-container .icon-md-phone {
  color: #1A051D;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .user-information .email-and-phone-container .user-phone-container .user-phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  color: #3F3356;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .line-divider {
  width: 100%;
  border-top: 1px solid #D3D3D3;
  margin: 20px 0;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .navigation-options {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .navigation-options .logout-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  cursor: pointer;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .navigation-options .logout-container .icon-container {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .navigation-options .logout-container .icon-container .icon-md-logout {
  font-size: 1.5em;
  color: #1A051D;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .user-information-and-navigation-options .navigation-options .logout-container .logout-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  color: #3F3356;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .logout-confirmation-message-container {
  width: 100%;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .logout-confirmation-message-container .logout-confirmation-message {
  margin-bottom: 20px;
  font-size: 1em;
  color: #1A051D;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .logout-confirmation-message-container .buttons-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .logout-confirmation-message-container .buttons-container .cancel-button {
  margin-right: 20px;
  border-color: #1A051D;
}
.react-modal-overlay.user-modal-overlay .react-modal.user-modal .user-modal-content .logout-confirmation-message-container .buttons-container .logout-button {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: #1A051D;
  text-align: center;
  color: #1A051D;
  font-size: 1em;
  cursor: #FFFFFF;
  user-select: none;
}

.footer-style {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  z-index: 1;
  background-color: var(--footer-style-bg-color);
  border-radius: 22px 22px 0px 0px;
  max-width: 480px !important;
}
.footer-style .footer {
  width: 100%;
  max-width: 480px;
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.footer-style .footer .language-selector-dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.footer-style .footer .language-selector-dropdown-container .title.language-selector-dropdown-title {
  margin-right: 1em;
  color: #1A051D;
}

.home-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .home-page {
    height: 115vh;
  }
}
.home-page .main-container {
  width: 100%;
  padding-top: 20px;
}
.home-page .main-container .qr-code-reader {
  margin-top: 100px;
}
.home-page .main-container .qr-code-reader .qr-code-heading {
  margin-top: 20px;
  text-align: center;
  font-size: 1rem;
}
.home-page .main-container .logo_style {
  display: flex;
  justify-content: center;
  margin-bottom: 42px;
}
@media (min-height: 500px) and (max-height: 799px) {
  .home-page .main-container .logo_style {
    margin-bottom: 15px;
  }
}
.home-page .main-container .heading {
  flex-shrink: 0;
  color: #1A051D;
  font-family: var(--secondary-font-semibold);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.home-page .main-container .heading.dark-text {
  color: #FFFFFF;
}
.home-page .main-container .sub-heading {
  width: 247px;
  height: 34px;
  flex-shrink: 0;
  color: #A5A5A5;
  font-family: var(--primary-font-regular);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.home-page .main-container .input-box-view {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 28px;
  margin-bottom: 28px;
}
@media (min-height: 500px) and (max-height: 799px) {
  .home-page .main-container .input-box-view {
    margin: 14px auto;
  }
}
.home-page .main-container .input-box-view .input {
  width: 45px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #CFC9D5;
  background: #FFFFFF;
  box-shadow: -13px 28px 9px 0px rgba(0, 0, 0, 0), -9px 18px 8px 0px rgba(0, 0, 0, 0.01), -5px 10px 7px 0px rgba(0, 0, 0, 0.05), -2px 4px 5px 0px rgba(0, 0, 0, 0.09), -1px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
.home-page .main-container .input-box-view .input .input-box-placeholder {
  color: "#000000" !important;
  font-weight: var(--input-placeholder-font-weight);
  font-size: 14px;
  font-family: var(--primary-font-regular);
}
.home-page .main-container .input-box-view .error-message {
  margin: 10px 0 0 15px;
  font-family: var(--primary-font-regular);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #EE5A52;
}
.home-page .main-container .divider {
  color: #A5A5A5;
  margin: 42px auto;
}
@media (min-height: 500px) and (max-height: 799px) {
  .home-page .main-container .divider {
    margin: 15px auto;
  }
}
.home-page .main-container .version-text {
  color: #A5A5A5;
  text-align: center;
  font-family: var(--primary-font-regular);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.authentication-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .authentication-page {
    height: 115vh;
  }
}
.authentication-page.send-otp-ui {
  background-color: #FFFFFF;
}
.authentication-page.verify-otp-ui {
  background-color: #F7F5F9;
}
.authentication-page .header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: #FFFFFF;
}
.authentication-page .header-container .header {
  width: 100%;
  max-width: 480px;
  padding: 0px 20px;
}
.authentication-page .header-container .header.send-otp-ui {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.authentication-page .header-container .header.send-otp-ui img.durolt-logo {
  width: auto;
  height: 4em;
  margin: 2.5em;
}
.authentication-page .header-container .header.verify-otp-ui {
  height: 3.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: relative;
}
.authentication-page .header-container .header.verify-otp-ui .back-button.icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: absolute;
  left: 10px;
  cursor: pointer;
}
.authentication-page .header-container .header.verify-otp-ui .back-button.icon-container .icon-md-chevron-left {
  font-size: 1.8em;
  color: #1A051D;
}
.authentication-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.authentication-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.authentication-page .main-container .main .send-otp-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
}
.authentication-page .main-container .main .send-otp-form .labels-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.authentication-page .main-container .main .send-otp-form .labels-container .form-label {
  width: 48%;
  padding: 0.5em;
  border-bottom: 2px solid transparent;
  margin-bottom: 2em;
  font-size: 1em;
  color: #808080;
  text-align: center;
}
.authentication-page .main-container .main .send-otp-form .labels-container .form-label.selected-login-option {
  border-bottom: 2px solid #92C23E;
}
.authentication-page .main-container .main .send-otp-form .label-container {
  width: 100%;
  margin-bottom: 2em;
}
.authentication-page .main-container .main .send-otp-form .label-container .form-label {
  width: 100%;
  font-size: 1em;
  color: #808080;
  text-align: left;
}
.authentication-page .main-container .main .send-otp-form .country-code-selector-and-mobile-number-input-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
}
.authentication-page .main-container .main .send-otp-form .country-code-selector-and-mobile-number-input-container .react-select.country-code-selector {
  width: 30%;
  font-size: 1em;
}
.authentication-page .main-container .main .send-otp-form .country-code-selector-and-mobile-number-input-container .react-select.country-code-selector div.country-code-selector__control {
  border-width: 0px 1px 0px 0px;
  border-radius: 0px;
}
.authentication-page .main-container .main .send-otp-form .country-code-selector-and-mobile-number-input-container .react-select.country-code-selector div.country-code-selector__control div.country-code-selector__indicators span.country-code-selector__indicator-separator {
  display: none;
}
.authentication-page .main-container .main .send-otp-form .country-code-selector-and-mobile-number-input-container .react-select.country-code-selector div.country-code-selector__control div.country-code-selector__indicators div.country-code-selector__indicator {
  padding-left: 0px;
}
.authentication-page .main-container .main .send-otp-form .country-code-selector-and-mobile-number-input-container .react-select.country-code-selector div.country-code-selector__menu {
  border-radius: 0px;
}
.authentication-page .main-container .main .send-otp-form .country-code-selector-and-mobile-number-input-container .react-select.country-code-selector div.country-code-selector__menu div.country-code-selector__menu-list {
  padding: 0px;
}
.authentication-page .main-container .main .send-otp-form .country-code-selector-and-mobile-number-input-container .react-select.country-code-selector div.country-code-selector__menu div.country-code-selector__menu-list div.country-code-selector__option {
  word-wrap: break-word;
}
.authentication-page .main-container .main .send-otp-form .country-code-selector-and-mobile-number-input-container .react-select.country-code-selector div.country-code-selector__menu div.country-code-selector__menu-list div.country-code-selector__option.country-code-selector__option--is-selected {
  background-color: #739E2E;
}
.authentication-page .main-container .main .send-otp-form .send-otp-button {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: 2em;
  cursor: pointer;
}
.authentication-page .main-container .main .send-otp-form .send-otp-button #rotating-loading-icon {
  width: 1.3em;
}
.authentication-page .main-container .main .verify-otp-form {
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
}
.authentication-page .main-container .main .verify-otp-form .form-label {
  padding: 0.5em 0;
  margin-bottom: 1em;
  font-size: 1em;
  color: #808080;
  text-align: left;
}
.authentication-page .main-container .main .verify-otp-form .verify-otp-button {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  cursor: pointer;
}
.authentication-page .main-container .main .verify-otp-form .verify-otp-button #rotating-loading-icon {
  width: 1.3em;
}
.authentication-page .main-container .main .form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  font-size: 1em;
}
.authentication-page .main-container .main .form-input.mobile-number-input {
  width: 70%;
  border-width: 0px;
}
.authentication-page .main-container .main .resend-otp-button {
  margin: 1.5em 0 1.6em 0;
  align-self: center;
  color: #739E2E;
  text-decoration: none;
  cursor: pointer;
}
.authentication-page .main-container .main .countdown-timer {
  margin: 1.5em 0 1.6em 0;
  align-self: center;
  color: #D0C9D6;
}
.authentication-page .main-container .main .otp-and-otpless-login-divider {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.authentication-page .main-container .main .otp-and-otpless-login-divider .divider-line {
  width: 44%;
  border-top: 1px solid #D3D3D3;
}
.authentication-page .main-container .main .otp-and-otpless-login-divider .or-text {
  margin: 0px 10px;
  font-size: 1em;
  color: #808080;
  vertical-align: middle;
}
.authentication-page .main-container .main .otpless-login-page-container {
  width: 100%;
}
.authentication-page .main-container .main .otpless-login-page-container .otpless-button {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
}
.authentication-page .main-container .main .link-my-phone-button {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  cursor: pointer;
}

.my-lockers-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .my-lockers-page {
    height: 115vh;
  }
}
@media (max-height: 700px) {
  .my-lockers-page {
    height: 120vh;
  }
}
.my-lockers-page .header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: #FFFFFF;
}
.my-lockers-page .header-container .header {
  width: 100%;
  max-width: 480px;
  padding: 0px 20px;
  height: 3.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
}
.my-lockers-page .header-container .header .page-heading {
  font-size: 1em;
  color: #1A051D;
}
.my-lockers-page .header-container .header img.durolt-logo {
  width: auto;
  height: 1.5em;
  margin: 1em 0em;
  position: absolute;
  right: 20px;
  bottom: 0px;
}
.my-lockers-page .header-container .header .user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: absolute;
  right: 20px;
  cursor: pointer;
}
.my-lockers-page .header-container .header .user .icon-md-account-circle {
  font-size: 1.8em;
  color: #1A051D;
}
.my-lockers-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .loading-icon-container {
  width: 3em;
}
.my-lockers-page .main-container .main .loading-text {
  color: #739E2E;
}
.my-lockers-page .main-container .main .booked-lockers-list {
  width: 100%;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 2em;
  background-color: #FFFFFF;
  cursor: pointer;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #92C23E;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .icon-container.locker-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #92C23E;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .icon-container.locker-icon-container .icon-md-square {
  font-size: 1.5em;
  color: #1A051D;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .locker-information {
  width: 100%;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .locker-information .locker-unit-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .locker-information .locker-unit-container .icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .locker-information .locker-unit-container .icon-container .icon-ri-door-lock-box-fill {
  font-size: 1.8em;
  color: #1A051D;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .locker-information .locker-unit-container .locker-unit {
  font-size: 1.5em;
  color: #1A051D;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .locker-information .locker-bank-name-and-remaining-time-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .locker-information .locker-bank-name-and-remaining-time-container .locker-bank-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .locker-information .locker-bank-name-and-remaining-time-container .locker-bank-name-container .icon-container {
  width: 40px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .locker-information .locker-bank-name-and-remaining-time-container .locker-bank-name-container .icon-container .icon-gi-lockers {
  font-size: 1.2em;
  color: #1A051D;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .locker-information .locker-bank-name-and-remaining-time-container .locker-bank-name-container .locker-bank-name {
  font-size: 0.8em;
  color: #1A051D;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .locker-information .locker-bank-name-and-remaining-time-container .remaining-time-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .locker-information .locker-bank-name-and-remaining-time-container .remaining-time-container .icon-container {
  width: 40px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .locker-information .locker-bank-name-and-remaining-time-container .remaining-time-container .icon-container .icon-io-timer {
  font-size: 1.2em;
  color: #1A051D;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .locker-information .locker-bank-name-and-remaining-time-container .remaining-time-container .remaining-time {
  font-size: 0.8em;
  color: #1A051D;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .icon-container.chevron-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-information-container .icon-container.chevron-icon-container .icon-md-chevron-right {
  font-size: 2em;
  color: #1A051D;
  transition: 0.2s;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker.accordion-expanded .locker-information-container .icon-container.chevron-icon-container .icon-md-chevron-right {
  transform: rotate(90deg);
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container {
  width: 100%;
  padding: 10px 10px 20px 10px;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .locker-bank-name-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .locker-bank-name-container .icon-container {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .locker-bank-name-container .icon-container .icon-gi-lockers {
  font-size: 1.5em;
  color: #739E2E;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .locker-bank-name-container .locker-bank-name {
  color: #1A051D;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .locker-bank-location-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .locker-bank-location-container .icon-container {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .locker-bank-location-container .icon-container .icon-md-location-on {
  font-size: 1.5em;
  color: #739E2E;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .locker-bank-location-container .locker-bank-location {
  color: #1A051D;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .booking-duration-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .booking-duration-container .icon-container {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .booking-duration-container .icon-container .icon-io-time {
  font-size: 1.5em;
  color: #739E2E;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .booking-duration-container .booking-duration {
  color: #1A051D;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-time-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-time-container .icon-container {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-time-container .icon-container .icon-io-timer {
  font-size: 1.5em;
  color: #739E2E;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-time-container .remaining-time {
  color: #1A051D;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-grace-time-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-grace-time-container .icon-container {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-grace-time-container .icon-container .icon-io-timer {
  font-size: 1.5em;
  color: #739E2E;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-time-and-overstay-time-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-time-and-overstay-time-container .remaining-time-container {
  width: 100%;
  margin: 0 10px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-time-and-overstay-time-container .remaining-time-container .icon-container {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-time-and-overstay-time-container .remaining-time-container .icon-container .icon-io-timer {
  font-size: 1.5em;
  color: #739E2E;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-time-and-overstay-time-container .overstay-time-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-time-and-overstay-time-container .overstay-time-container .icon-container {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-time-and-overstay-time-container .overstay-time-container .icon-container .icon-md-more-time {
  font-size: 1.5em;
  color: #FF6464;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .remaining-time-and-overstay-time-container .overstay-time-container .overstay-time {
  margin-right: 10px;
  color: #FF6464;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .warning-message-container {
  width: 100%;
  padding: 10px;
  border: 1px solid #D3D3D3;
  border-radius: 0px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: #D0C9D6;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .warning-message-container .warning-message {
  width: 100%;
  font-size: 0.9em;
  color: #1A051D;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .warning-message-container.overstay-warning {
  background-color: #FF6464;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .warning-message-container.overstay-warning .warning-message {
  color: #FFFFFF;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .overstay-pay-button {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .slide-button {
  border: 1px solid #DFF8B1;
  border-radius: 5px;
  background-color: #DFF8B1;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .slide-button .slide-button-overlay {
  border-radius: 5px;
  background-color: #92C23E;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .slide-button .slide-button-overlay .slide-button-caret {
  border-radius: 5px;
  background-color: #739E2E;
}
.my-lockers-page .main-container .main .booked-lockers-list .booked-locker .locker-detailed-information-container .slide-button .slide-button-overlay .slide-button-caret #chevrons-right {
  width: 2em;
}
.my-lockers-page .main-container .main .booked-lockers-list .warning-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  color: #808080;
}
.my-lockers-page .main-container .main .error-message {
  margin-bottom: 2em;
  text-align: center;
  color: #FF6464;
}
.my-lockers-page .main-container .main .try-again-button {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-lockers-page .main-container .main .book-another-locker-button {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
}
.my-lockers-page .main-container .main .book-another-locker-button.disabled-button {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-disabled-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-disabled-text-color);
  font-size: 1em;
  cursor: not-allowed;
  user-select: none;
}
.my-lockers-page .info-row {
  width: 100%;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.my-lockers-page .info-row .title {
  color: #808080;
  font-size: large;
}
.my-lockers-page .info-row .value {
  color: #1A051D;
}
.my-lockers-page .qr-code-reader-container {
  width: 100%;
  max-width: 480px;
  padding: 20px;
}
.my-lockers-page .qr-code-reader-container .qr-code-reader {
  width: 100%;
}

.css-ypiqx9-MuiDialogContent-root {
  background-color: #FFFFFF;
}

.css-knqc4i-MuiDialogActions-root {
  background-color: #FFFFFF;
}

.react-modal-overlay.payment-confirmation-modal-overlay {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(160, 160, 160, 0.5333333333);
  backdrop-filter: blur(5px);
}
.react-modal-overlay.payment-confirmation-modal-overlay .react-modal.payment-confirmation-modal {
  width: 100%;
  max-width: 480px;
  padding: 40px 20px;
  border-radius: 5px;
  background-color: #FFFFFF;
}
.react-modal-overlay.payment-confirmation-modal-overlay .react-modal.payment-confirmation-modal .payment-confirmation-modal-content {
  width: 100%;
}
.react-modal-overlay.payment-confirmation-modal-overlay .react-modal.payment-confirmation-modal .payment-confirmation-modal-content .payment-info {
  width: 100%;
  margin-bottom: 2em;
}
.react-modal-overlay.payment-confirmation-modal-overlay .react-modal.payment-confirmation-modal .payment-confirmation-modal-content .buttons-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.react-modal-overlay.payment-confirmation-modal-overlay .react-modal.payment-confirmation-modal .payment-confirmation-modal-content .buttons-container .cancel-button {
  margin-right: 20px;
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: #FF6464;
  text-align: center;
  color: #C75252;
  font-size: 1em;
  cursor: #FFFFFF;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.react-modal-overlay.payment-confirmation-modal-overlay .react-modal.payment-confirmation-modal .payment-confirmation-modal-content .buttons-container .pay-button {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.react-modal-overlay.qr-code-reader-modal-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.react-modal-overlay.qr-code-reader-modal-overlay .react-modal.qr-code-reader-modal {
  width: 100%;
  height: 100%;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: rgba(32, 32, 32, 0.5333333333);
  backdrop-filter: blur(5px);
}
.react-modal-overlay.qr-code-reader-modal-overlay .react-modal.qr-code-reader-modal .qr-code-reader-container {
  width: 100%;
  max-width: 480px;
  padding: 20px;
}
.react-modal-overlay.qr-code-reader-modal-overlay .react-modal.qr-code-reader-modal .qr-code-reader-container .qr-code-reader {
  width: 100%;
}
.react-modal-overlay.qr-code-reader-modal-overlay .react-modal.qr-code-reader-modal .qr-code-reader-container .qr-code-reader section {
  border-radius: 5px;
}
.react-modal-overlay.qr-code-reader-modal-overlay .react-modal.qr-code-reader-modal .qr-code-reader-container .qr-code-reader section div {
  box-shadow: #92C23E 0px 0px 0px 2px inset !important;
}
.react-modal-overlay.qr-code-reader-modal-overlay .react-modal.qr-code-reader-modal .qr-code-reader-container .button-container {
  width: 100%;
  margin: 2em 0;
}
.react-modal-overlay.qr-code-reader-modal-overlay .react-modal.qr-code-reader-modal .qr-code-reader-container .button-container .cancel-button {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: #FF6464;
  text-align: center;
  color: #C75252;
  font-size: 1em;
  cursor: #FFFFFF;
  user-select: none;
}

/*
.locker-size-page {
    @include page-element-dimensions;
    @include flex-item(column, center, space-between);

    .main-container {
        width: 100%;
        @include flex-item;

        .main {
            @include main-element-dimensions;
            @include flex-item(column);


            .locker-size-form-heading {
                font-size: 1em;
                color: $app-grey-color;
                text-align: left;
            }

            .locker-size-form {
                width: 100%;
                margin: 2em 0em;

                .form-item {
                    width: 100%;
                    border-radius: 5px;
                    margin-bottom: 2em;
                    background-color: $app-tea-green-color;
                    @include flex-item(column, center, flex-start);

                    .form-label {
                        @include primary-button($app-yellow-green-color, $app-green-color);

                        .form-input {
                            display: none;
                        }
                    }

                    .selected {
                        background-image: linear-gradient($app-green-color, $app-yellow-green-color) !important;
                    }

                    .blurred{
                        opacity: 0.5;
                    }

                    .booking-period-form {
                        width: 100%;
                        padding: 0px 20px 20px;

                        .locker-price, .booking-period, .total-amount {
                            margin-top: 20px;
                            @include flex-item(row, center, space-between);

                            .title, .value {
                                color: $app-dark-purple-color;
                            }
                        }

                        .booking-start-and-end-times {
                            margin-top: 0.25em;
                            @include flex-item(row, center, flex-end);

                            .value {
                                color: $app-dark-purple-color;
                            }

                            .booking-duration-warning {
                                color: $app-bittersweet-color;
                            }
                        }

                        .booking-period {
                            .form-input {
                                width: 50%;
                                padding: 8px;
                                @include item-border(1px, solid, $app-light-grey-color, 5px);
                            }

                            .input-duration {
                                background-color: $app-white-color;
                                width: 300px;
                                margin-left: 2em;
                            }
                        }
                    }
                }
            }
            }
    }
}

.info-row {
    width: 100%;
    padding: 5px 0;
    @include flex-item(row, flex-start, space-between, nowrap);

    .title {
        color: $app-grey-color;
    }

    .value {
        color: $app-dark-purple-color;
        font-size: 1rem;

        &.booking-duration-value {
            @include flex-item(column, flex-end, flex-start, nowrap);
        }
    }
}
*/
.locker-size-page {
  height: 100vh;
  height: auto !important;
  min-height: 100vh;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .locker-size-page {
    height: 115vh;
  }
}
.locker-size-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-size-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-size-page .main-container .main .locker-size-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion {
  width: 100%;
  border: 1px solid rgba(185, 224, 102, 0.2666666667);
  border-radius: var(--btn-border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 0;
  margin-bottom: 2em;
  background-color: rgba(185, 224, 102, 0.1333333333);
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion:last-child {
  margin-bottom: 0;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-header {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-header .size-name {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-header .units-available {
  text-transform: capitalize;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-header .icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: absolute;
  right: 20px;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-header .icon-container .accordion-icon {
  font-size: 2rem;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-details {
  width: 100%;
  padding: 10px;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-details .heading {
  text-align: center;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-details .table-header-row {
  padding: 4px 0;
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-details .table-header-row .duration-heading {
  padding-left: 56px;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-details .table-header-row .price-heading {
  padding-right: 16px;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-details .table-info-row {
  padding: 12px 0;
  border: 1px solid lightgrey;
  border-radius: 8px;
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-details .table-info-row.selected {
  background-color: #B9E066;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-details .table-info-row:last-child {
  margin-bottom: 0;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-details .table-info-row .icon-and-duration {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-details .table-info-row .icon-and-duration .radio-icon {
  width: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-details .table-info-row .icon-and-duration .slot-duration {
  font-size: 1.2rem;
}
.locker-size-page .main-container .main .locker-size-list .locker-size.accordion .accordion-details .table-info-row .slot-price {
  padding-right: 16px;
  font-size: 1.2rem;
}
.locker-size-page .main-container .main .book-button-container {
  width: 50%;
  margin: 16px 0;
}
.locker-size-page .main-container .main .booking-instructions {
  width: 100%;
  padding: 16px 0;
  border-top: 1px solid lightgrey;
}
.locker-size-page .main-container .main .booking-instructions .heading {
  margin-bottom: 10px;
  font-size: 1.4em;
  text-align: center;
}
.locker-size-page .main-container .main .booking-instructions .instructions-list .list-item {
  margin-bottom: 10px;
  background-color: transparent;
}
.locker-size-page .main-container .main .booking-instructions .instructions-list .list-item .icon-container {
  min-width: auto;
  padding-right: 16px;
}
.locker-size-page .main-container .main .booking-instructions .instructions-list .list-item .icon-container .circle-icon {
  font-size: 14px;
}

#booking-confirmation-modal .MuiDialogContent-root {
  padding-bottom: 0;
}
#booking-confirmation-modal .MuiDialogContent-root .modal-content .booking-details {
  padding-top: 20px;
  border-top: 1px solid lightgrey;
}
#booking-confirmation-modal .MuiDialogContent-root .modal-content .booking-details .info-row {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
#booking-confirmation-modal .MuiDialogContent-root .modal-content .booking-details .info-row:last-child {
  margin-bottom: 0;
}
#booking-confirmation-modal .MuiDialogContent-root .modal-content .booking-details .info-row .left-column {
  font-size: 1.2em;
  text-align: left;
}
#booking-confirmation-modal .MuiDialogContent-root .modal-content .booking-details .info-row .right-column {
  font-size: 1.4em;
  text-align: right;
}

.payment-details-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  background-color: #F7F5F9;
}
@media (max-width: 320px) {
  .payment-details-page {
    height: 115vh;
  }
}
.payment-details-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.payment-details-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.custom-dialog-redirect .icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.custom-dialog-redirect .icon-container .icon-md-error {
  font-size: 36px;
  color: #C75252;
}
.custom-dialog-redirect .error-message {
  margin-top: 18px;
  font-size: 18px;
  color: #C75252;
  text-align: center;
}
.custom-dialog-redirect .redirection-message {
  margin-top: 18px;
  color: #808080;
  text-align: center;
}

.payment-completion-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  background-color: #F7F5F9;
}
@media (max-width: 320px) {
  .payment-completion-page {
    height: 115vh;
  }
}
.payment-completion-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.payment-completion-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.payment-completion-page .main-container .main .loading-icon-container {
  width: 3em;
}
.payment-completion-page .main-container .main .booking-info-container {
  width: 100%;
  padding: 1em;
  border-radius: 5px;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  background-color: #FFFFFF;
  box-shadow: #E0E0E0 0px 0px 2px 0px;
}
.payment-completion-page .main-container .main .booking-info-container .booking-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.payment-completion-page .main-container .main .booking-info-container .booking-status-container .icon-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.payment-completion-page .main-container .main .booking-info-container .booking-status-container .icon-container .icon-md-check-circle {
  font-size: 3em;
  color: #739E2E;
}
.payment-completion-page .main-container .main .booking-info-container .booking-status-container .booking-status-message {
  margin: 0.6em 0 1em 0;
  font-size: 1.2em;
  color: #739E2E;
  text-transform: capitalize;
}
.payment-completion-page .main-container .main .booking-info-container .booking-info {
  border-top: 1px solid #D0C9D6;
  width: 100%;
}
.payment-completion-page .main-container .main .booking-info-container .booking-info .info-row {
  padding-top: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: relative;
}
.payment-completion-page .main-container .main .booking-info-container .booking-info .info-row .icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.payment-completion-page .main-container .main .booking-info-container .booking-info .info-row .icon-container .booking-info-icon {
  font-size: 1.4em;
  color: #1A051D;
}
.payment-completion-page .main-container .main .booking-info-container .booking-info .info-row .title-and-value-container {
  margin-left: 10px;
}
.payment-completion-page .main-container .main .booking-info-container .booking-info .info-row .title-and-value-container .title {
  font-size: 12px;
  color: #808080;
}
.payment-completion-page .main-container .main .booking-info-container .booking-info .info-row .title-and-value-container .value {
  font-size: 1em;
  color: #1A051D;
}
.payment-completion-page .main-container .main .booking-info-container .booking-info .info-row .locker-size {
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: #1A051D;
  font-size: 1.3em;
  font-weight: bold;
  color: #FFFFFF;
  position: absolute;
  right: 0;
}
.payment-completion-page .main-container .main .slide-button-container {
  width: 100%;
}
.payment-completion-page .main-container .main .slide-button-container .loading-icon-and-message-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.payment-completion-page .main-container .main .slide-button-container .loading-icon-and-message-container .loading-icon-container {
  width: 3em;
}
.payment-completion-page .main-container .main .slide-button-container .loading-icon-and-message-container .loading-message {
  margin-top: 0.5em;
  color: #739E2E;
}
.payment-completion-page .main-container .main .slide-button-container .slide-button {
  border: 1px solid #DFF8B1;
  border-radius: 5px;
  background-color: #DFF8B1;
}
.payment-completion-page .main-container .main .slide-button-container .slide-button .slide-button-overlay {
  border-radius: 5px;
  background-color: #92C23E;
}
.payment-completion-page .main-container .main .slide-button-container .slide-button .slide-button-overlay .slide-button-caret {
  border-radius: 5px;
  background-color: #739E2E;
}
.payment-completion-page .main-container .main .slide-button-container .slide-button .slide-button-overlay .slide-button-caret #chevrons-right {
  width: 2em;
}
.payment-completion-page .main-container .main .open-locker-button {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.payment-completion-page .main-container .main .open-locker-button #rotating-loading-icon {
  width: 1.3em;
}

/*
.locker-opened-page {
    @include page-element-dimensions;
    @include flex-item(column, center, space-between);
    background-color: $app-ghost-white-color;

    .main-container {
        width: 100%;
        @include flex-item;

        .main {
            @include main-element-dimensions;
            @include flex-item(column);

            .loading-icon-container {
                width: 3em
            }

            .locker-opened-info {
                width: 100%;
                margin-bottom: 1em;
                @include flex-item;
            }

            .locker-usage-instructions {
                width: 100%;
                margin-bottom: 4em;
                @include flex-item;
            }
            .remaining-time-donut-chart-container{
                padding-bottom: 20px;
            }
        }
    }
}

.custom-dialog-qr-code{
    width: 400px;
}
*/
.locker-opened-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .locker-opened-page {
    height: 115vh;
  }
}
.locker-opened-page .main-container {
  width: 100%;
  min-height: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-opened-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  min-height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-opened-page .main-container .main .locker-open-icon-container {
  margin-bottom: 1.5em;
}
.locker-opened-page .main-container .main .locker-unit, .locker-opened-page .main-container .main .close-door-instructions {
  margin-bottom: 1em;
  color: var(--tertiary-text-color);
  font-size: 18px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
  text-align: center;
}

.locker-released-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  background-color: #F7F5F9;
}
@media (max-width: 320px) {
  .locker-released-page {
    height: 115vh;
  }
}
.locker-released-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-released-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-released-page .main-container .main .loading-icon-container {
  width: 3em;
}
.locker-released-page .main-container .main .locker-released-info {
  width: 100%;
  margin-bottom: 2em;
  text-align: center;
}
.locker-released-page .main-container .main .locker-released-instructions {
  width: 100%;
  text-align: center;
}
.locker-released-page .main-container .main .buttons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-released-page .main-container .main .buttons-container .my-lockers-button, .locker-released-page .main-container .main .buttons-container .book-new-locker-button {
  margin-top: 2em;
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
}
.locker-released-page .main-container .main .buttons-container .book-new-locker-button.disabled-button {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-disabled-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-disabled-text-color);
  font-size: 1em;
  cursor: not-allowed;
  user-select: none;
}

.organisation-list-page {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  height: 100vh;
}
@media (max-width: 320px) {
  .organisation-list-page {
    height: 115vh;
  }
}
.organisation-list-page .box-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 20px;
}
.organisation-list-page .listItem {
  width: 100%;
  background-color: #FFFFFF;
  padding-block: 15px;
  border-radius: 8px;
}
.organisation-list-page .itemTitle {
  margin-left: 20px;
  font-size: 20px;
  font-family: var(--primary-font-regular);
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 15px 0px;
}
@media (min-width: 360px) and (max-width: 400px) and (min-height: 629px) and (max-height: 699px) {
  .button-container {
    padding: 10px 0px;
  }
}
.button-container .button {
  width: 100%;
  border-radius: var(--btn-border-radius);
  font-size: 1rem;
  font-family: var(--primary-font-medium);
  text-transform: none;
}
.button-container .button.primary {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
}
.button-container .button.secondary {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-secondary-contained-bg-image);
  text-align: center;
  color: var(--secondary-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
}
.button-container .button.warning {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-warning-contained-bg-image) !important;
  text-align: center;
  color: #FFFFFF;
  font-size: 1em;
  cursor: pointer;
  user-select: none;
}
.button-container .button.disabled-button {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-disabled-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-disabled-text-color);
  font-size: 1em;
  cursor: not-allowed;
  user-select: none;
}
.button-container .button.blurred {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background: var(--btn-blurred-bg-image);
  text-align: center;
  color: var(--btn-blurred-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
  border: 1px solid #FFFFFF;
  backdrop-filter: blur(15px);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1490196078);
}
.button-container .button.default {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: linear-gradient(#92C23E, #669F00);
  text-align: center;
  color: #FFFFFF;
  font-size: 1em;
  cursor: pointer;
  user-select: none;
}

.support-screen-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .support-screen-page {
    height: 115vh;
  }
}
.support-screen-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.support-screen-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.support-screen-page .main-container .main .container {
  text-align: center;
  padding-top: 20;
}
.support-screen-page .main-container .main .title {
  font-size: 18px;
}
.support-screen-page .main-container .main .LinkingGroup {
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.support-screen-page .main-container .main .flexGrow {
  flex-grow: 1;
}

.change-pin-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .change-pin-page {
    height: 115vh;
  }
}
.change-pin-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.change-pin-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
}
.change-pin-page .main-container .main .inputView {
  width: "100%";
}
.change-pin-page .main-container .main .input {
  color: #1A051D;
  box-shadow: 0px 7px 10px -5px rgba(0, 0, 0, 0.32);
  height: 40;
  background-color: #FFFFFF;
  border-radius: 6;
  font-family: var(--primary-font-regular);
  font-size: 15px;
  margin-bottom: 20px;
}
.change-pin-page .main-container .main .forgetPINText {
  color: #92C23E;
  display: flex;
  font-family: var(--primary-font-regular);
  font-size: 16px;
  justify-content: right;
  padding-bottom: 10px;
}
.change-pin-page .main-container .main .modal-title-style {
  font-size: 20px !important;
  line-height: 10;
}

.new-reserve-locker-page {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  padding: 0px;
}
.new-reserve-locker-page .main {
  width: 100%;
  height: 100%;
  position: relative;
}
.new-reserve-locker-page .main .bottom-container {
  margin-top: 20px;
}
.new-reserve-locker-page .main .bottom-container .or-text {
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 2px;
}
.new-reserve-locker-page .main .favourites-text {
  font-size: 1.25rem;
  margin-top: 2px;
}
.new-reserve-locker-page .main .scan-package-text {
  font-size: 1.25rem;
  text-align: center;
  margin-top: 20px;
}
.new-reserve-locker-page .main .barcode-scan-text {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 34px;
  font-family: var(--primary-font-regular);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 16.94px;
  margin-top: 20px;
  text-align: center;
}
.new-reserve-locker-page .button {
  padding-left: 20px;
  padding-right: 20px;
}

.favourites {
  overflow-y: scroll;
  margin-top: 20px;
}
.favourites.fav-max-height {
  max-height: 20vh;
}
.favourites .listItem {
  width: 100%;
  background-color: #FFFFFF;
  padding-block: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.favourites .listItem .initial-view {
  background-color: #DFF8B1;
  border-radius: 16px;
  padding: 10px;
  margin-right: 15px;
  width: 52px;
  height: 52px;
}
.favourites .listItem .initial-view .initial-text {
  text-align: center;
  color: #1A051D;
}
.favourites .listItem .bank-name {
  color: #1A051D;
}
.favourites .listItem .favourite-view {
  margin-left: auto;
  margin-bottom: auto;
  margin-top: auto;
}
.favourites .itemTitle {
  margin-left: 20px;
  font-size: 20px;
  font-family: var(--primary-font-regular);
}
.favourites .noFavoritesText {
  color: #1A051D;
  font-size: 15px;
  font-family: var(--primary-font-medium);
  line-height: 20px;
  padding-top: 20px;
  text-align: center;
}
.favourites .noFavoritesText.dark-no-fav-text {
  color: #FFFFFF;
}

.select-zone {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
}
@media (max-width: 320px) {
  .select-zone {
    height: 115vh;
  }
}
.select-zone .main-container {
  width: 100%;
}
.select-zone .main-container .listItem {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 8px;
}
.select-zone .main-container .itemTitle {
  margin-left: 20px;
  font-size: 20px;
  font-family: var(--primary-font-regular);
  color: #1A051D;
}

.sublocation-one-page {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .sublocation-one-page {
    height: 115vh;
  }
}
.sublocation-one-page .main-container {
  width: 100%;
}
.sublocation-one-page .main-container .listItem {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 8px;
}
.sublocation-one-page .main-container .itemTitle {
  margin-left: 20px;
  font-size: 20px;
  font-family: var(--primary-font-regular);
  color: #1A051D;
}

.sublocation-two-page {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .sublocation-two-page {
    height: 115vh;
  }
}
.sublocation-two-page .main-container {
  width: 100%;
}
.sublocation-two-page .main-container .listItem {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 8px;
}
.sublocation-two-page .main-container .itemTitle {
  margin-left: 20px;
  font-size: 20px;
  font-family: var(--primary-font-regular);
  color: #1A051D;
}

.select-locker-bank-page {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .select-locker-bank-page {
    height: 115vh;
  }
}
.select-locker-bank-page .main-container {
  width: 100%;
  margin-bottom: 90px;
  overflow-y: scroll;
}
.select-locker-bank-page .main-container .no-locker-bank-text {
  color: #1A051D;
  font-size: 0.9375rem;
  font-family: var(--primary-font-medium);
  line-height: 20px;
  padding-top: 20px;
  text-align: center;
}
.select-locker-bank-page .main-container .no-locker-bank-text.dark-text {
  color: #FFFFFF;
}

.sublocation-three-page {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .sublocation-three-page {
    height: 115vh;
  }
}
.sublocation-three-page .main-container {
  width: 100%;
}
.sublocation-three-page .main-container .listItem {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 8px;
}
.sublocation-three-page .main-container .itemTitle {
  margin-left: 20px;
  font-size: 20px;
  font-family: var(--primary-font-regular);
  color: #1A051D;
}

.about-screen-page {
  height: 100vh;
}
@media (max-width: 320px) {
  .about-screen-page {
    height: 115vh;
  }
}
.about-screen-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.about-screen-page .main-container .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.about-screen-page .main-container .main .container-about {
  align-items: center;
  justify-content: center;
}
.about-screen-page .main-container .main .linking_group {
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.team-locker-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .team-locker-page {
    height: 115vh;
  }
}
.team-locker-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.team-locker-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.team-locker-page .main-container .main .container {
  flex: 1;
  position: relative;
}
.team-locker-page .main-container .main .title {
  font-size: 22px;
  color: #1A051D;
  line-height: 26px;
  font-family: var(--secondary-font-semibold);
  padding-bottom: 18px;
}
.team-locker-page .main-container .main .commentBox {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  font-size: 16px;
  color: #1A051D;
  text-align: left;
  min-height: 160px;
  font-family: var(--primary-font-regular);
  margin-bottom: 20px;
}
.team-locker-page .main-container .main .commentBox .MuiOutlinedInput-root fieldset {
  border: none;
}

.term-and-condition-page {
  text-align: justify;
}
.term-and-condition-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.term-and-condition-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
}
.term-and-condition-page .main-container .main .section {
  margin-bottom: 30px;
}
.term-and-condition-page .main-container .main .title {
  margin-bottom: 10px;
  font-family: var(--secondary-font-semibold);
}
.term-and-condition-page .main-container .main .paraText {
  margin-bottom: 10px;
  font-family: var(--primary-font-regular);
}
.term-and-condition-page .main-container .main .listItem {
  padding-left: 10px;
}

.sidebar-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .sidebar-container {
    height: 115vh;
  }
}
.sidebar-container .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.sidebar-container .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.sidebar-container .main-container .main .profileContent .list-item {
  font-size: 40px;
}
.sidebar-container .main-container .main .profileContent .profileInfo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.sidebar-container .main-container .main .profileContent .profileInfo .profileImage {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}
.sidebar-container .main-container .main .profileContent .profileInfo .profileInfoView {
  display: flex;
  flex-direction: column;
}
.sidebar-container .main-container .main .profileContent .profileInfo .profileInfoView .MuiTypography-body1 {
  font-weight: bold;
}
.sidebar-container .main-container .main .profileContent .profileInfo .profileInfoView .MuiTypography-body2 {
  color: grey;
}
.sidebar-container .main-container .main .profileContent .divider {
  margin: 10px 0;
}
.sidebar-container .main-container .main .profileContent .versionName {
  padding-left: 2px;
  color: #D0C9D6;
  font-size: 0.75rem;
  font-family: var(--primary-font-medium);
  line-height: 5px;
}
.sidebar-container .main-container .main .profileContent .MuiListItem-root {
  padding: 10px 20px;
}
.sidebar-container .main-container .main .profileContent .MuiListItemIcon-root {
  min-width: 40px;
}
.sidebar-container .main-container .main .profileContent .MuiListItemText-primary {
  font-size: 16px;
}
.sidebar-container .main-container .main .profileContent .versionName {
  text-align: center;
  margin-top: 20px;
  color: red !important;
}
.sidebar-container .main-container .main .loaderOuterView {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sidebar-container .main-container .main .modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.sidebar-container .main-container .main .modalContent .modalTitle {
  font-size: 20px;
  line-height: 5;
}
.sidebar-container .main-container .main .modalContent .modalBody {
  font-size: 20px;
  padding-bottom: 20px;
}
.sidebar-container .main-container .main .modalContent .modalActions {
  display: flex;
  gap: 20px;
  justify-content: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .css-1ceh56v-MuiPaper-root-MuiDrawer-paper {
    margin-left: 18%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .css-1ceh56v-MuiPaper-root-MuiDrawer-paper {
    margin-left: 25%;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .css-1ceh56v-MuiPaper-root-MuiDrawer-paper {
    margin-left: 30%;
  }
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .css-1ceh56v-MuiPaper-root-MuiDrawer-paper {
    margin-left: 33%;
  }
}
@media (min-width: 1920px) {
  .css-1ceh56v-MuiPaper-root-MuiDrawer-paper {
    margin-left: 36%;
  }
}

.sub-header {
  width: 100%;
  padding: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
  background-color: var(--sub-header-background-color);
}
.sub-header.dark {
  background-color: var(--app-dark-background-color);
}
.sub-header #back-button {
  position: absolute;
  left: 28px;
}
.sub-header #back-button .back-icon-and-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.sub-header #back-button .back-icon-and-title .back-title {
  color: #1A051D;
  font-size: 16;
  font-family: var(--primary-font-semibold);
  font-weight: 400;
}
.sub-header .header-title {
  color: #1A051D;
  font-size: 16;
  font-family: var(--primary-font-semibold);
  font-weight: 400;
  text-align: center;
  line-height: 2;
}

.main-header-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.main-header-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  padding: 0;
}
.main-header-page .main-container .main .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #FFFFFF;
  justify-content: space-between;
  padding: 15px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.0784313725);
}
.main-header-page .main-container .main .header.dark-header {
  background-color: #1A051D;
}
.main-header-page .main-container .main .header-contain {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-header-page .main-container .main .header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.main-header-page .main-container .main .logo {
  width: 86px;
  height: 32px;
}

.favourite-locker-page {
  height: 100vh;
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
}
@media (max-width: 320px) {
  .favourite-locker-page {
    height: 115vh;
  }
}

.select-user-screen-page {
  height: 100vh;
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .select-user-screen-page {
    height: 115vh;
  }
}
.select-user-screen-page .input {
  color: #1A051D;
  box-shadow: 0px 7px 10px -5px rgba(0, 0, 0, 0.32);
  height: 40;
  background-color: #FFFFFF;
  border-radius: 6;
  font-family: var(--primary-font-regular);
  font-size: 15px;
  margin-top: 5px;
}
.select-user-screen-page .listItem {
  width: 100%;
  background-color: #FFFFFF;
  padding-block: 15px;
  border-radius: 8px;
  margin-top: 10px;
}
.select-user-screen-page .listItem .initial-view {
  background-color: #DFF8B1;
  border-radius: 16px;
  padding: 10px;
  margin-right: 15px;
  width: 52px;
  height: 52px;
}
.select-user-screen-page .listItem .itemTitle {
  font-size: 12px;
  font-family: var(--primary-font-regular);
}

.profile-update-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-y: scroll;
}
@media (max-width: 320px) {
  .profile-update-page {
    height: 115vh;
  }
}
.profile-update-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.profile-update-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
}
.profile-update-page .main-container .main .inputView {
  width: 100%;
}
.profile-update-page .main-container .main .input {
  box-shadow: 0px 7px 10px -5px rgba(0, 0, 0, 0.32);
  height: 40;
  background-color: #FFFFFF;
  font-family: var(--primary-font-regular);
  font-size: 15px;
  margin-bottom: 20px;
}
@media (min-width: 320px) and (max-width: 400px) {
  .profile-update-page .main-container .main .input {
    margin-bottom: 5px;
  }
}
.profile-update-page .main-container .main .input .autocomplete-dark-theme .MuiAutocomplete-endAdornment .MuiIconButton-root {
  color: #1A051D;
}
.profile-update-page .main-container .main .custom-input-view {
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (min-width: 320px) and (max-width: 400px) {
  .profile-update-page .main-container .main .custom-input-view {
    margin-bottom: 5px;
  }
}
.profile-update-page .main-container .main .scanQRbutton {
  margin-bottom: 20px;
}
@media (min-width: 320px) and (max-width: 400px) {
  .profile-update-page .main-container .main .scanQRbutton {
    margin-bottom: 5px;
  }
}
.profile-update-page .main-container .main .ProfileImage {
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
}
.profile-update-page .main-container .main .ProfileImage .iconContainer {
  position: absolute;
  bottom: 10px;
  left: 60%;
  border-radius: 50%;
  padding: 5px;
}
.profile-update-page .main-container .main .container {
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.connect-mobile-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .connect-mobile-page {
    height: 115vh;
  }
}
.connect-mobile-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.connect-mobile-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.connect-mobile-page .main-container .main .container {
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.pleaseWaitMainView {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
}
.pleaseWaitMainView .pleaseWaitSubView {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: 6px;
  gap: 20px;
}
.pleaseWaitMainView .pleaseWaitSubView .loader {
  color: var(--app-loader-color);
}
.pleaseWaitMainView .pleaseWaitSubView .pleaseWaitText {
  color: #1A051D;
  font-size: 1rem;
  font-family: var(--primary-font-regular);
}

.parcel-details-screen-page {
  height: 100vh;
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
}
@media (max-width: 320px) {
  .parcel-details-screen-page {
    height: 115vh;
  }
}
.parcel-details-screen-page .main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 20px;
}
.parcel-details-screen-page .main-container .top-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
}
.parcel-details-screen-page .main-container .top-container .bank-details-view {
  margin-left: 15px;
}
.parcel-details-screen-page .main-container .top-container .bank-details-view .locker-bank-detail-text {
  font-family: var(--primary-font-medium);
  font-weight: bold;
}
.parcel-details-screen-page .main-container .parcel-details-text-view .camera-view {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.parcel-details-screen-page .main-container .parcel-details-text-view .camera-view .icon-view {
  display: flex;
  align-self: center;
}
.parcel-details-screen-page .main-container .parcel-details-text-view .camera-view .take-photo-text {
  text-align: center;
}
.parcel-details-screen-page .main-container .input-view {
  width: 100%;
}
.parcel-details-screen-page .main-container .input-view .input {
  color: #1A051D;
  box-shadow: 0px 7px 10px -5px rgba(0, 0, 0, 0.32);
  height: 40;
  background-color: #FFFFFF;
  border-radius: 6;
  font-family: var(--primary-font-regular);
  font-size: 15px;
  margin-bottom: 20px;
}

.assignment-selection-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .assignment-selection-page {
    height: 115vh;
  }
}
.assignment-selection-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.assignment-selection-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.drop-parcel-screen-page {
  height: 100vh;
}
@media (max-width: 320px) {
  .drop-parcel-screen-page {
    height: 115vh;
  }
}
.drop-parcel-screen-page .main-container .top-container .parcel-details {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.drop-parcel-screen-page .main-container .top-container .parcel-details .text-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.drop-parcel-screen-page .main-container .top-container .parcel-details .parcel-details-text {
  margin-left: 20px;
  margin-bottom: 3px;
  font-family: var(--secondary-font-semibold);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media (min-width: 360px) and (max-width: 400px) and (min-height: 629px) and (max-height: 699px) {
  .drop-parcel-screen-page .main-container .top-container .parcel-details .parcel-details-text {
    font-size: 0.75rem;
  }
}
.drop-parcel-screen-page .main-container .top-container .parcel-details .parcel-details-subtext {
  margin-left: 20px;
  margin-bottom: 3px;
  font-family: var(--primary-font-regular);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (min-width: 360px) and (max-width: 400px) and (min-height: 629px) and (max-height: 699px) {
  .drop-parcel-screen-page .main-container .top-container .parcel-details .parcel-details-subtext {
    font-size: 0.5625rem;
  }
}
.drop-parcel-screen-page .main-container .top-container .parcel-details .parcel-details-subtext2 {
  margin-left: 20px;
  font-family: var(--primary-font-regular);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.drop-parcel-screen-page .main-container .top-container .pickup-user-details {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.drop-parcel-screen-page .main-container .top-container .pickup-user-details .initial-view {
  background-color: #DFF8B1;
  padding: 10px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 55px;
  flex-shrink: 0;
  border-radius: 6px;
}
.drop-parcel-screen-page .main-container .top-container .pickup-user-details .initial-view .initial-text {
  text-align: center;
  font-size: 25px;
}
.drop-parcel-screen-page .main-container .top-container .pickup-user-details .text-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.drop-parcel-screen-page .main-container .top-container .pickup-user-details .text-view .user-details {
  font-family: var(--primary-font-regular);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
}
@media (min-width: 360px) and (max-width: 400px) and (min-height: 629px) and (max-height: 699px) {
  .drop-parcel-screen-page .main-container .top-container .pickup-user-details .text-view .user-details {
    font-size: 0.75rem;
  }
}
.drop-parcel-screen-page .main-container .top-container .divider {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #808080;
  font-size: 0.9375rem;
}
@media (min-width: 360px) and (max-width: 400px) and (min-height: 629px) and (max-height: 699px) {
  .drop-parcel-screen-page .main-container .top-container .divider {
    font-size: 0.75rem;
  }
}
@media (min-height: 600px) and (max-height: 700px) {
  .drop-parcel-screen-page .main-container .top-container .divider {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.drop-parcel-screen-page .main-container .top-container .durolt-logo {
  width: 56px;
  height: 55px;
  flex-shrink: 0;
  border-radius: 6px;
}
.drop-parcel-screen-page .main-container .bottom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
.drop-parcel-screen-page .main-container .bottom-container .locker-open-icon {
  width: 150px;
  height: 150px;
}
@media (min-width: 360px) and (max-width: 400px) {
  .drop-parcel-screen-page .main-container .bottom-container .locker-open-icon {
    width: 120px;
    height: 120px;
  }
}
@media (min-width: 360px) and (max-width: 400px) and (min-height: 629px) and (max-height: 699px) {
  .drop-parcel-screen-page .main-container .bottom-container .locker-open-icon {
    width: 70px;
    height: 70px;
  }
}
.drop-parcel-screen-page .main-container .bottom-container .locked-icon {
  width: 150px;
  height: 150px;
}
@media (min-width: 360px) and (max-width: 400px) {
  .drop-parcel-screen-page .main-container .bottom-container .locked-icon {
    width: 120px;
    height: 120px;
  }
}
.drop-parcel-screen-page .main-container .bottom-container .locker-door-details {
  margin-top: 20px;
}
.drop-parcel-screen-page .main-container .bottom-container .locker-door-details .locker-text {
  font-family: var(--primary-font-regular);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 14.52px;
  text-align: center;
  margin-bottom: 10px;
}
.drop-parcel-screen-page .main-container .bottom-container .locker-door-details .locker-no {
  font-family: var(--secondary-font-bold);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 14.52px;
  text-align: center;
  color: #709C16;
}
.drop-parcel-screen-page .main-container .bottom-container .locker-door-details .locker-no.door-text-red {
  color: #F39590;
}
.drop-parcel-screen-page .main-container .bottom-container .locker-door-details .locker-door-text {
  margin-top: 20px;
  font-family: var(--secondary-font-semibold);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;
}
@media (min-width: 360px) and (max-width: 375px) {
  .drop-parcel-screen-page .main-container .bottom-container .locker-door-details .locker-door-text {
    margin-top: 10px;
  }
}
.drop-parcel-screen-page .main-container .bottom-container .initial-text {
  text-align: center;
  font-size: 25px;
}
.drop-parcel-screen-page .main-container .bottom-container .slide-button {
  border: 1px solid #DFF8B1;
  border-radius: var(--slider-border-radius);
  width: 100%;
  margin-top: 40px;
}
@media (min-width: 360px) and (max-width: 400px) {
  .drop-parcel-screen-page .main-container .bottom-container .slide-button {
    margin-top: 20px;
  }
}
.drop-parcel-screen-page .main-container .bottom-container .slide-button .slide-button-overlay {
  border-radius: 8px;
  background-color: #92C23E;
}
.drop-parcel-screen-page .main-container .bottom-container .slide-button .slide-button-overlay .slide-button-caret {
  border-radius: 5px;
  background-color: #739E2E;
}
.drop-parcel-screen-page .main-container .bottom-container .slide-button .slide-button-overlay .slide-button-caret #chevrons-right {
  width: 40px;
}
.drop-parcel-screen-page .main-container .bottom-container .open-locker-view {
  margin-left: 30px;
}
.drop-parcel-screen-page .main-container .bottom-container .open-locker-text {
  font-family: var(--primary-font-regular);
  font-size: 1.25rem;
}
.drop-parcel-screen-page .main-container .bottom-container .button-view {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  width: 100%;
}
.drop-parcel-screen-page .main-container .bottom-container .button-view .done-and-cancel-btn {
  gap: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.notification-page {
  height: 100vh;
}
@media (max-width: 320px) {
  .notification-page {
    height: 115vh;
  }
}
.notification-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.notification-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.notification-page .main-container .main .container {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.notification-page .main-container .main .assignText {
  font-size: 18px;
  font-family: "SegoeUI-Bold", sans-serif;
  color: #1A051D;
  padding-top: 50px;
  padding-bottom: 13px;
  line-height: 24px;
}
.notification-page .main-container .main .assignPara {
  color: #808080;
  font-size: 16px;
  text-align: center;
  padding-bottom: 50px;
  font-family: "SegoeUI", sans-serif;
}
.notification-page .main-container .main .activityView {
  background-color: #FFFFFF;
  border-radius: 14px;
  margin-top: 12px;
}
.notification-page .main-container .main .flexView {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.notification-page .main-container .main .logTime {
  color: #808080;
  font-size: 11px;
  padding-left: 4px;
  font-family: var(--primary-font-regular);
  line-height: 13px;
}
.notification-page .main-container .main .Action {
  color: #1A051D;
  font-size: 14px;
  font-family: var(--primary-font-regular);
  text-align: center;
}
.notification-page .main-container .main .userName {
  font-family: var(--primary-font-regular);
}
.notification-page .main-container .main .notificationList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.notification-page .main-container .main .flexGrow {
  flex-grow: 1;
}
.notification-page .main-container .main .responsiveFont {
  font-size: 15px;
}
.notification-page .main-container .main .icon-style {
  margin-left: auto;
}

.login-screen-page {
  height: 100vh;
}
@media (max-width: 320px) {
  .login-screen-page {
    height: 115vh;
  }
}
.login-screen-page.img {
  object-fit: fill;
  background-repeat: round;
  width: 100%;
}
.login-screen-page .main-container {
  width: 100%;
  overflow-y: scroll;
}
.login-screen-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
@media (min-height: 500px) and (max-height: 768px) {
  .login-screen-page .main-container .main {
    padding-top: 0;
  }
}
.login-screen-page .main-container .main .logo_style {
  margin-top: 30px;
  margin-bottom: 30px;
}
.login-screen-page .main-container .main .formView {
  width: 100%;
}
.login-screen-page .main-container .main .formView .text {
  font-family: var(--secondary-font-semibold);
  font-size: 2.125rem;
  font-weight: 700;
}
@media (max-width: 320px) {
  .login-screen-page .main-container .main .formView .text {
    font-size: 1.875rem;
  }
}
.login-screen-page .main-container .main .formView .anotherText {
  max-width: 230px;
  font-family: var(--primary-font-regular);
  font-size: 0.9375rem;
  opacity: 0.7;
}
.login-screen-page .main-container .main .formView .login-error-message {
  margin: 10px 0 0 15px;
  font-family: var(--primary-font-regular);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #EE5A52;
}
.login-screen-page .main-container .main .formView .terms-text {
  margin-top: 22px;
  font-family: var(--primary-font-regular);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.login-screen-page .main-container .main .formView .terms-text .href-link {
  color: #739E2E;
}
.login-screen-page .main-container .main .formView .inputContainer {
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.login-screen-page .main-container .main .formView .inputContainer .emailInput {
  color: #1A051D;
  box-shadow: 0px 7px 10px -5px rgba(0, 0, 0, 0.32);
  background-color: var(--btn-blurred-bg-image);
  border-radius: 6px;
  border: var(--input-border-color);
  font-family: var(--primary-font-regular);
  font-size: 0.9375rem;
  display: inline-block;
}
.login-screen-page .main-container .main .formView .inputContainer .emailInput .placeholder-color {
  color: var(--input-placeholder-color) !important;
  font-weight: var(--input-placeholder-font-weight);
  font-size: 14px;
  font-family: var(--primary-font-regular);
}
.login-screen-page .main-container .main .formView .inputContainer .input input {
  width: auto;
}
.login-screen-page .main-container .main .formView .inputContainer .button {
  height: 30px;
  position: absolute;
  right: 3px;
  top: 42px;
  justify-content: center;
  color: #92C23E;
  text-transform: none;
}
.login-screen-page .main-container .main .formView .login-button {
  position: relative;
  margin-top: 20px;
}
.login-screen-page .main-container .main .formView .textORMainContainer {
  display: flex;
  align-items: center;
}
.login-screen-page .main-container .main .formView .textORLine {
  flex: 1;
  height: 1px;
  background-color: #ECE9F1;
}
.login-screen-page .main-container .main .formView .textORContainer {
  display: flex;
  align-items: center;
}
.login-screen-page .main-container .main .formView .shareText {
  font-size: 1.0625rem;
  color: #D0C9D6;
  font-family: var(--primary-font-semibold);
  margin: 0 10px;
}
.login-screen-page .main-container .main .formView .scanTxt {
  color: #92C23E;
  font-size: 1rem;
  font-family: var(--primary-font-medium);
}
.login-screen-page .main-container .main .formView .scanView {
  border-color: #92C23E;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  margin-top: 15px;
  color: #92C23E;
}
.login-screen-page .main-container .main .loginPageFooterText {
  margin-top: 20px;
}
.login-screen-page .main-container .main .loginPageFooterText .footerTextStyle {
  text-align: center;
  font-size: 0.875rem;
  font-family: var(--primary-font-regular);
}
.login-screen-page .main-container .main .loginPageFooterText .duroltTextStyle {
  font-family: var(--primary-font-semibold);
}
.login-screen-page .main-container .main .otpless-login-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.verify-otp-page {
  height: 100vh;
}
@media (max-width: 320px) {
  .verify-otp-page {
    height: 115vh;
  }
}
.verify-otp-page.img {
  object-fit: fill;
  background-repeat: round;
  width: 100%;
}
.verify-otp-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.verify-otp-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.verify-otp-page .main-container .main .app-logo {
  margin-bottom: 100px;
}
.verify-otp-page .main-container .main .resend-btn-text {
  color: var(--btn-text-variant-color);
  font-family: var(--primary-font-regular);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 14.52px;
  text-align: center;
  text-decoration: underline;
  text-transform: capitalize;
}
.verify-otp-page .main-container .main .grid {
  position: relative;
  margin-top: 100px;
  margin-bottom: 50px;
  width: 100%;
}
@media (min-height: 500px) and (max-height: 790px) {
  .verify-otp-page .main-container .main .grid {
    margin-top: 25px;
    margin-bottom: 10px;
  }
}
.verify-otp-page .main-container .main .grid .grid-icon {
  width: 183px;
  height: 135px;
}
@media (min-height: 500px) and (max-height: 790px) {
  .verify-otp-page .main-container .main .grid .grid-icon {
    width: 130px;
    height: 95px;
  }
}
.verify-otp-page .main-container .main .focus {
  position: absolute;
  bottom: 0;
  left: 118px;
}
.verify-otp-page .main-container .main .focus .cone-icon {
  width: 138px;
  height: 237px;
}
@media (min-height: 500px) and (max-height: 790px) {
  .verify-otp-page .main-container .main .focus .cone-icon {
    width: 100px;
    height: 170px;
  }
}
.verify-otp-page .main-container .main .input {
  color: #1A051D;
  box-shadow: 0px 7px 10px -5px rgba(0, 0, 0, 0.32);
  height: 40;
  border: var(--input-border-color);
  background-color: var(--btn-blurred-bg-image);
  border-radius: 6;
  font-family: var(--primary-font-regular);
  font-size: 0.9375rem;
  margin: 20px;
}
.verify-otp-page .main-container .main .verify-input-error {
  margin: 10px 0 0 15px;
  font-family: var(--primary-font-regular);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #EE5A52;
}
.verify-otp-page .main-container .main .timeTxt {
  text-align: center;
  font-family: var(--primary-font-regular);
  padding-bottom: 24px;
  margin-top: 10px;
  font-size: 0.75rem;
  color: var(--secondary-text-color);
  font-weight: 400;
  line-height: 14.52px;
}

.locker-list-page {
  height: 100vh;
}
@media (max-width: 320px) {
  .locker-list-page {
    height: 115vh;
  }
}
.locker-list-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-list-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-list-page .main-container .main .list-container {
  position: relative;
  width: 100%;
}
.locker-list-page .main-container .main .list-container .list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.locker-list-page .main-container .main .list-container .list-header .locker-size {
  opacity: 0.7;
  font-size: 0.8125rem;
  line-height: 15px;
  font-family: var(--primary-font-regular);
}
.locker-list-page .main-container .main .list-container .list-header .layout-text {
  font-size: 13px;
  color: #92C23E;
  line-height: 15px;
  font-family: var(--primary-font-semibold);
}
.locker-list-page .main-container .main .container-view {
  align-items: center;
  justify-content: center;
}
.locker-list-page .main-container .main .container-view .assign-para {
  font-size: 18px;
  font-family: "SegoeUI-Bold", sans-serif;
  color: #1A051D;
  padding-top: 37px;
  text-align: center;
}

.locker-layout-page {
  height: 100vh;
}
@media (max-width: 320px) {
  .locker-layout-page {
    height: 115vh;
  }
}
.locker-layout-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-layout-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-layout-page .main-container .main .list-header {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.locker-layout-page .main-container .main .list-header .lockerView {
  color: #92C23E;
  font-size: 13px;
  line-height: 15px;
  font-family: var(--primary-font-semibold);
  padding-bottom: 16px;
}
.locker-layout-page .main-container .main .responsiveImageStyle {
  width: 100%;
}
.locker-layout-page .main-container .main .responsiveImageStyle .content {
  background-color: #ECE9F1;
  border-color: #D0C9D6;
  border-width: 1px;
}
.locker-layout-page .main-container .main .flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
  padding: 10px;
  margin-top: 20px;
}
.locker-layout-page .main-container .main .flexRow .colorStatus {
  width: 20px;
  height: 20px;
  background-color: #FFFFFF;
}
.locker-layout-page .main-container .main .flexRow .availableText {
  font-size: 15px;
  font-family: var(--primary-font-regular);
  padding-left: 12px;
}

.select-locker-size-page {
  height: 100vh;
}
@media (max-width: 320px) {
  .select-locker-size-page {
    height: 115vh;
  }
}
.select-locker-size-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.select-locker-size-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
}
.select-locker-size-page .main-container .main .assignmentListStyle {
  padding: 15px;
  border-radius: 5px;
}

.my-locker-list-page {
  padding: 0 10px;
}
.my-locker-list-page .lockergrid {
  margin-top: 20px;
  max-height: 65vh;
  overflow-y: auto;
}
.my-locker-list-page .lockergrid .badge-view {
  margin-bottom: 20px;
}
.my-locker-list-page .lockergrid .badge-view .badge-pink {
  background-color: #F39590;
}
.my-locker-list-page .lockergrid .badge-view .badge-green {
  background-color: #92C23E;
}
.my-locker-list-page .lockergrid .divider-view {
  width: 100%;
}
.my-locker-list-page .lockergrid .divider-view .divider {
  color: #A5A5A5;
  margin: 20px auto;
}
@media (min-height: 500px) and (max-height: 799px) {
  .my-locker-list-page .lockergrid .divider-view .divider {
    margin: 15px auto;
  }
}
.my-locker-list-page .lockergrid .request-digits {
  text-align: center;
  color: #1A051D;
  font-size: 0.9375rem;
  font-family: var(--primary-font-regular);
  line-height: 16px;
  max-width: 300px;
}
.my-locker-list-page .lockergrid .no-locker-data {
  text-align: center;
  font-size: 1rem;
  font-family: var(--primary-font-regular);
  line-height: 16px;
  max-width: 300px;
}
.my-locker-list-page .search-bar-view {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.my-locker-list-page .search-bar-view .barcode-float-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#739E2E, #92C23E);
  width: 40px;
  height: 38px;
  border-radius: 5px;
  color: #FFFFFF;
  margin-left: 5px;
}
.my-locker-list-page .loader {
  display: flex;
  justify-content: center;
}
.my-locker-list-page .loader .loader-style {
  color: #92C23E;
}
.my-locker-list-page .LockerWrapper {
  border-radius: 14px;
}
.my-locker-list-page .LockerWrapper .lockerContainer {
  width: 100%;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem {
  position: relative;
  display: flex;
  border-radius: 12px;
  padding: 8px;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #FFFFFF;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem.listItem-dimmed::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 12px;
  z-index: 1;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem.no-bottom-radius {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem.list-item-blocked {
  background: linear-gradient(#FF6464, #C75252);
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .content-wrapper {
  width: 70%;
  flex-direction: row;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .content {
  display: flex;
  align-items: center;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .content .imageGroup {
  position: relative;
  display: inline-block;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .content .contentInfo {
  margin-left: 12px;
  width: 100%;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .content .contentInfo .locker-title {
  font-size: 1.2rem;
  font-family: var(--primary-font-regular);
  max-width: 190px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  margin-top: 3px;
  line-height: normal;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .content .contentInfo .locker-title.locker-title-block {
  color: #FFFFFF;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .content .contentInfo .text-one {
  font-size: 0.625rem;
  font-family: var(--primary-font-regular);
  line-height: 16px;
  opacity: 0.6;
  text-align: left;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .content .contentInfo .text-one.text-one-block {
  color: #FFFFFF;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .content .contentInfo .textline-three {
  font-family: "SFProText-SemiBold", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 3px;
  text-align: left;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .content .contentInfo .textline-three.textline-three-block {
  color: #FFFFFF;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .openLockerSlider {
  background-color: #92C23E;
  border-radius: 14px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 8px;
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
  margin-left: -6px;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .dotted-line-up {
  display: grid;
  align-content: center;
  justify-content: center;
  width: 30%;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .dotted-line-up .dotted-line-text {
  text-align: right;
  margin: 8px 0 8px 12px;
  width: 25px;
  color: #808080;
  font-family: var(--primary-font-regular);
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .cod-view {
  width: 50px;
  margin-top: 25px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #D7746F;
  background-color: #F39590;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .cod-view .cod-view-text {
  font-family: var(--primary-font-medium);
  font-size: 0.75rem;
  color: #FFFFFF;
  font-weight: 400;
  line-height: 9.68px;
  text-align: center;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .overstay-indicator {
  padding: 4px 8px;
  border-radius: 8px;
  align-self: center;
  background-color: #EE5A52;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .overstay-indicator .overstay-text {
  font-size: 1.3em;
  font-weight: bold;
  color: white;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .usage-timer {
  align-self: center;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .usage-timer .usage-timer-text {
  font-size: 1.3em;
}
.my-locker-list-page .LockerWrapper .lockerContainer .listItem .arrow-icon {
  align-self: center;
}
.my-locker-list-page .LockerWrapper .lockerContainer .extra-locker-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 13px;
}
.my-locker-list-page .LockerWrapper .lockerContainer .extra-locker-details .user-details {
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
}
.my-locker-list-page .LockerWrapper .lockerContainer .extra-locker-details .user-details .initial-view {
  background-color: #DFF8B1;
  border-radius: 16px;
  padding: 10px;
  width: 75px;
  height: 57px;
}
.my-locker-list-page .LockerWrapper .lockerContainer .extra-locker-details .user-details .contentInfo {
  margin-left: 12px;
  width: 100%;
}
.my-locker-list-page .LockerWrapper .lockerContainer .extra-locker-details .user-details .contentInfo .locker-title {
  font-size: 0.8125rem;
  font-family: var(--primary-font-regular);
  max-width: 190px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  margin-top: 3px;
  line-height: normal;
}
.my-locker-list-page .LockerWrapper .lockerContainer .extra-locker-details .user-details .contentInfo .locker-title.locker-title-block {
  color: #FFFFFF;
}
.my-locker-list-page .LockerWrapper .lockerContainer .extra-locker-details .user-details .contentInfo .text-one {
  font-size: 0.625rem;
  font-family: var(--primary-font-regular);
  line-height: 16px;
  opacity: 0.6;
  text-align: left;
  width: 106px;
}
.my-locker-list-page .LockerWrapper .lockerContainer .extra-locker-details .user-details .contentInfo .text-one.text-one-block {
  color: #FFFFFF;
}
.my-locker-list-page .LockerWrapper .lockerContainer .extra-locker-details .user-details .contentInfo .textline-three {
  font-family: "SFProText-SemiBold", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 3px;
  text-align: left;
  width: 137px;
}
.my-locker-list-page .LockerWrapper .lockerContainer .extra-locker-details .user-details .contentInfo .textline-three.textline-three-block {
  color: #FFFFFF;
}
.my-locker-list-page .LockerWrapper .lockerContainer .booking-info-container {
  width: 100%;
  padding: 0 8px;
}
.my-locker-list-page .LockerWrapper .lockerContainer .booking-info-container .info-row {
  padding: 6px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.my-locker-list-page .LockerWrapper .lockerContainer .booking-info-container .info-row .icon-container {
  margin-right: 10px;
  color: var(--btn-text-variant-color);
}
.my-locker-list-page .LockerWrapper .lockerContainer .booking-info-container .info-row .icon-container.overstay {
  color: #EE5A52;
}
.my-locker-list-page .LockerWrapper .lockerContainer .booking-info-container .info-row .title {
  min-width: 120px;
  margin-right: 10px;
  text-align: left;
}
.my-locker-list-page .LockerWrapper .lockerContainer .booking-info-container .info-row .value {
  text-align: left;
}
.my-locker-list-page .LockerWrapper .lockerContainer .booking-info-container .overstay-info {
  padding: 10px;
  border-radius: 8px;
  margin: 10px 0;
  background-color: #EE5A52;
}
.my-locker-list-page .LockerWrapper .lockerContainer .booking-info-container .overstay-info .overstay-heading {
  margin-bottom: 5px;
  font-size: 1.4em;
  font-weight: bold;
  color: white;
  text-align: center;
}
.my-locker-list-page .LockerWrapper .lockerContainer .booking-info-container .overstay-info .overstay-text {
  width: 100%;
  border-top: 1px solid white;
  padding-top: 5px;
  font-size: 1.3em;
  font-weight: bold;
  color: white;
}
.my-locker-list-page .LockerWrapper .lockerContainer .buttons-view {
  width: 100%;
}
.my-locker-list-page .LockerWrapper .lockerContainer .buttons-view .buttons-container {
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-locker-list-page .LockerWrapper .lockerContainer .buttons-view .buttons-container #open-without-checkout-button {
  width: 100%;
  font-size: 1.3em;
}
.my-locker-list-page .LockerWrapper .lockerContainer .buttons-view .buttons-container #open-with-checkout-button {
  width: 100%;
  margin-left: 10px;
  font-size: 1.3em;
}
.my-locker-list-page .LockerWrapper .lockerContainer .buttons-view .buttons-container #overstay-button {
  font-size: 1.3em;
}
.my-locker-list-page .LockerWrapper .lockerContainer .slider-view {
  width: 100%;
}
.my-locker-list-page .LockerWrapper .lockerContainer .slider-view .slider {
  border: 1px solid #DFF8B1;
  border-radius: 14px;
  height: 47px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.my-locker-list-page .LockerWrapper .lockerContainer .slider-view .cod-slider {
  background-color: #92C23E;
}
.my-locker-list-page .noAssignedLockerBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.my-locker-list-page .noAssignedLockerBlock .dashicon {
  width: 150px;
  height: 150px;
}
.my-locker-list-page .noAssignedLockerBlock .assignText {
  font-size: 1.125rem;
  font-family: "SegoeUI-Bold", sans-serif;
  padding-top: 57px;
  padding-bottom: 13px;
}
.my-locker-list-page .assignNewlocker {
  position: fixed;
  bottom: 90px;
  right: 0;
}
.my-locker-list-page .assignNewlocker .floatingBtn {
  margin-top: 32px;
  margin-right: 10px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .my-locker-list-page .assignNewlocker {
    right: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .my-locker-list-page .assignNewlocker {
    right: 30%;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .my-locker-list-page .assignNewlocker {
    right: 32%;
  }
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .my-locker-list-page .assignNewlocker {
    right: 35%;
  }
}
@media (min-width: 1920px) {
  .my-locker-list-page .assignNewlocker {
    right: 38%;
  }
}
.my-locker-list-page .shareText {
  font-size: 1.0625rem;
  color: #808080;
  font-family: var(--primary-font-semibold);
  padding-right: 6px;
}

.locker-more-option .main-container .mainWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.locker-more-option .main-container .mainWrapper .lockerIcon {
  display: flex;
  padding: 5px;
  margin: 10px 10px 0 auto;
  border: 1px solid #92C23E;
  border-radius: 6px;
}
.locker-more-option .main-container .mainWrapper .lockerIcon .lockerStatusText {
  color: #92C23E;
  font-size: 13px;
  font-family: var(--primary-font-medium);
  text-transform: uppercase;
  padding-left: 5px;
}
.locker-more-option .main-container .mainWrapper .lockerIcon .closeIconText {
  color: red;
}
.locker-more-option .main-container .mainWrapper .lockedIcon {
  border-color: #92C23E;
}
.locker-more-option .main-container .lockerDetailsBlock {
  padding: 0 20px 0 20px;
}
.locker-more-option .main-container .lockerDetailsBlock .imageGroup {
  position: relative;
  display: inline-block;
  bottom: 15px;
  left: 8%;
}
.locker-more-option .main-container .lockerDetailsBlock .lockerInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.locker-more-option .main-container .lockerDetailsBlock .lockerInfo .lockerPosition {
  position: relative;
}
.locker-more-option .main-container .lockerDetailsBlock .lockerInfo .lockerdetails {
  padding-left: 20px;
}
.locker-more-option .main-container .lockerDetailsBlock .lockerInfo .lockerdetails .lockerTitle {
  font-size: 0.9375rem;
  font-family: var(--primary-font-medium);
  line-height: 18px;
  max-width: 220px;
  margin-bottom: 4px;
}
.locker-more-option .main-container .lockerDetailsBlock .lockerInfo .lockerdetails .lockerLocation {
  font-size: 13px;
  font-family: var(--primary-font-regular);
  line-height: 15px;
  margin-bottom: 4px;
}
.locker-more-option .main-container .lockerDetailsBlock .lockerInfo .lockerdetails .lockerText {
  font-size: 11px;
  font-family: var(--primary-font-regular);
  line-height: 13px;
  opacity: 0.6;
  margin-bottom: 4px;
}
.locker-more-option .main-container .tabBarLabel {
  font-size: 0.8125rem;
  font-family: var(--primary-font-medium);
  line-height: 15px;
  color: #808080;
}
.locker-more-option .main-container .tabBarLabel.Mui-selected {
  color: #7CB01F;
}
.locker-more-option .main-container .releaseTabLabel {
  color: #FF6464;
}
.locker-more-option .main-container .indicatorStyle {
  background-color: #7CB01F;
}
.locker-more-option .main-container .activityViewWrap {
  flex-grow: 1;
  padding-top: 15px;
  padding-bottom: 15px;
  padding: 15px 20px 15px 20px;
}
.locker-more-option .main-container .activityViewWrap .activityContainer {
  display: flex;
  padding: 20px;
}
.locker-more-option .main-container .activityViewWrap .activityContainer .logDate {
  color: #808080;
  font-family: var(--primary-font-semibold);
  font-size: 15px;
  line-height: 20px;
  padding-right: 6px;
}
.locker-more-option .main-container .activityViewWrap .activityContainer .activityDate {
  flex: 1;
  height: 0.5px;
  background-color: #000;
  align-self: center;
}
.locker-more-option .main-container .activityViewWrap .activityView {
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.locker-more-option .main-container .activityViewWrap .activityView .logUserName {
  font-size: 15px;
  color: #1A051D;
  font-family: var(--secondary-font-semibold);
  text-align: center;
}
.locker-more-option .main-container .activityViewWrap .activityView .flexView {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.locker-more-option .main-container .activityViewWrap .activityView .logTime {
  color: #808080;
  font-size: 11px;
  padding-left: 4px;
  font-family: var(--primary-font-medium);
  line-height: 13px;
}
.locker-more-option .main-container .logsNotAvailableBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 27px;
}
.locker-more-option .main-container .logsNotAvailableBlock .NoLockerText {
  font-size: 18px;
  font-family: "SegoeUI-Bold", sans-serif;
  color: #1A051D;
  padding-top: 15px;
  padding-bottom: 13px;
}
.locker-more-option .main-container .shareLockerContainer {
  padding: 24px 20px;
}
.locker-more-option .main-container .shareLockerContainer .shareLockerBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.locker-more-option .main-container .shareLockerContainer .shareLockerBlock .share-text {
  color: #739E2E;
}
.locker-more-option .main-container .shareLockerContainer .shareLockerBlock .shareTitle {
  font-size: 1.0625rem;
  font-family: var(--primary-font-semibold);
}
.locker-more-option .main-container .shareLockerContainer .shareTextStyle {
  padding-top: 35px;
  justify-content: center;
  text-align: center;
}
.locker-more-option .main-container .shareLockerContainer .shareTextStyle .noShareText {
  color: #3F3356;
  font-size: 18px;
  font-family: "SegoeUI-Bold", sans-serif;
  text-align: center;
  padding-top: 32px;
  padding-bottom: 10px;
}
.locker-more-option .main-container .shareLockerContainer .shareTextStyle .shareWithText {
  color: #808080;
  font-size: 16px;
  font-family: "SegoeUI", sans-serif;
  text-align: center;
  padding-bottom: 30px;
}
.locker-more-option .main-container .shareLockerContainer .listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 14px;
  margin-top: 6px;
  padding: 8px 21px 8px 6px;
  width: 100%;
  justify-content: space-between;
}
.locker-more-option .main-container .shareLockerContainer .listItem .multipleShareRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.locker-more-option .main-container .shareLockerContainer .listItem .multipleShareRow .userView {
  width: 52px;
  height: 52px;
  background-color: #DFF8B1;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.locker-more-option .main-container .shareLockerContainer .listItem .multipleShareRow .userName {
  color: #1A051D;
  font-size: 22px;
  font-family: var(--secondary-font-semibold);
  text-align: center;
}
.locker-more-option .main-container .shareLockerContainer .listItem .multipleShareColumn {
  display: flex;
  flex-direction: column;
  padding-left: 14px;
}
.locker-more-option .main-container .shareLockerContainer .listItem .multipleShareColumn .info {
  color: #808080;
  font-size: 11px;
  font-family: var(--primary-font-regular);
  line-height: 15px;
  max-width: 245px;
}
.locker-more-option .main-container .shareLockerContainer .listItem .multipleShareColumn .name {
  color: #1A051D;
  font-size: 15px;
  font-family: var(--primary-font-medium);
  line-height: 20px;
}
.locker-more-option .main-container .shareLockerContainer .listItem .removeIcon {
  color: #92C23E;
}
.locker-more-option .main-container .shareLockerContainer .listItem .isOwner {
  color: #739E2E;
  font-size: 13px;
  font-family: var(--primary-font-medium);
}
.locker-more-option .main-container .shareLockerContainer .modalWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}
.locker-more-option .main-container .shareLockerContainer .modalWrapper .modalView {
  padding: 25px 30px;
  background-color: #fff;
  border-radius: 6px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.locker-more-option .main-container .shareLockerContainer .modalWrapper .modalView .modalText {
  text-align: center;
  color: #1A051D;
  font-size: 22px;
  font-family: var(--secondary-font-semibold);
  padding-bottom: 14px;
}
.locker-more-option .main-container .shareLockerContainer .modalWrapper .modalRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 23px;
  justify-content: center;
}
.locker-more-option .main-container .shareLockerContainer .cancel {
  color: #FF6464 !important;
}

.server-error .errorMessageText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  text-align: center;
}
.server-error .errorMessageText .serverMainErrorText {
  font-size: 18px;
  font-family: var(--primary-font-semibold);
  color: #1A051D;
  padding-top: 16px;
  padding-bottom: 16px;
  max-width: 400px;
}
.server-error .errorMessageText .serverSubErrorText {
  font-size: 14px;
  font-family: var(--primary-font-semibold);
  color: #1A051D;
  padding-bottom: 16px;
  max-width: 400px;
}

.release-locker-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .release-locker-page {
    height: 115vh;
  }
}
.release-locker-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.release-locker-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.timer .timerUI {
  color: #FFFFFF;
  font-size: 12px;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.assign-locker-page {
  height: 100vh;
}
@media (max-width: 320px) {
  .assign-locker-page {
    height: 115vh;
  }
}
.assign-locker-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.assign-locker-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.assign-locker-page .main-container .main .assignLockerWrapper {
  background-color: #FFFFFF;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.assign-locker-page .main-container .main .assignLockerWrapper .lockerInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.assign-locker-page .main-container .main .assignLockerWrapper .lockerInfo .lockerdetails {
  padding-left: 20px;
}
.assign-locker-page .main-container .main .assignLockerWrapper .lockerInfo .lockerdetails .lockerTitle {
  color: #1A051D;
  font-size: 15px;
  font-family: var(--primary-font-medium);
}
.assign-locker-page .main-container .main .assignLockerWrapper .lockerInfo .lockerdetails .lockerLocation {
  color: #808080;
  font-size: 13px;
  font-family: var(--primary-font-regular);
}
.assign-locker-page .main-container .main .assignLockerWrapper .lockerInfo .lockerdetails .lockerText {
  color: #1A051D;
  font-size: 11px;
  font-family: var(--primary-font-regular);
  width: 180px;
}
.assign-locker-page .main-container .main .assignLockerWrapper .LockedText {
  color: #4E7509;
  font-size: 11px;
  width: 70px;
  text-align: center;
  padding-top: 6px;
  font-family: var(--primary-font-regular);
}
.assign-locker-page .main-container .main .assignLockerBlock {
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
.assign-locker-page .main-container .main .assignLockerBlock .assignLockerSubBlock {
  position: relative;
}
.assign-locker-page .main-container .main .assignLockerBlock .lockerPrefix {
  color: #84799A;
  font-family: "SFProText-Semibold";
  font-size: 18px;
  text-align: center;
  padding-top: 10px;
}
.assign-locker-page .main-container .main .lockerAssignedText {
  color: #1A051D;
  font-family: "SFProText-Semibold";
  font-size: 17px;
  text-align: center;
  padding-bottom: 22px;
}
.assign-locker-page .main-container .main .backHomeText {
  color: #92C23E;
  font-size: 13px;
  font-family: var(--primary-font-medium);
  padding-bottom: 25px;
}
.assign-locker-page .main-container .main .lockerInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.assign-locker-page .main-container .main .lockerInfo .releaseText {
  color: #3F3356;
  font-size: 16px;
  font-family: var(--primary-font-regular);
  padding-left: 5px;
}
.assign-locker-page .main-container .main .openLockerSlider {
  width: 100%;
  padding-top: 20px;
}
.assign-locker-page .main-container .main .containerView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 24px;
}
.assign-locker-page .main-container .main .containerView .errorMessageText {
  color: #1A051D;
  font-size: 14px;
  font-family: "SegoeUI-Bold";
  text-align: center;
  padding-top: 30px;
}

.open-locker-page {
  height: 100vh;
}
@media (max-width: 320px) {
  .open-locker-page {
    height: 115vh;
  }
}
.open-locker-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.open-locker-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.open-locker-page .main-container .main .lockerContainer {
  padding: 12px 20px;
  width: 100%;
}
.open-locker-page .main-container .main .lockerContainer .lockerInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.open-locker-page .main-container .main .lockerContainer .lockerInfo .imageGroup {
  position: relative;
}
.open-locker-page .main-container .main .lockerContainer .lockerInfo .imageGroup .badge {
  position: absolute;
  right: 6px;
  bottom: 9px;
  padding: 1px;
  color: #FFFFFF;
}
.open-locker-page .main-container .main .lockerContainer .lockerInfo .lockerdetails {
  padding-left: 20px;
}
.open-locker-page .main-container .main .lockerContainer .lockerInfo .lockerdetails .lockerTitle {
  font-size: 15px;
  font-family: var(--primary-font-medium);
}
.open-locker-page .main-container .main .lockerContainer .lockerInfo .lockerdetails .lockerLocation {
  color: #808080;
  font-size: 13px;
  font-family: var(--primary-font-regular);
}
.open-locker-page .main-container .main .lockerContainer .lockerInfo .lockerdetails .lockerText {
  font-size: 11px;
  font-family: var(--primary-font-regular);
  width: 180px;
}
.open-locker-page .main-container .main .lockerContainer .openText {
  color: #FF6464;
  font-size: 0.6875rem;
  text-align: center;
  padding: 6px;
  font-family: var(--primary-font-regular);
  text-align: left;
}
.open-locker-page .main-container .main .lockerBlock {
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.open-locker-page .main-container .main .lockerBlock .lockerSubBlock {
  position: relative;
}
.open-locker-page .main-container .main .lockerBlock .lockerSubBlock .locker-open-icon {
  width: 150px;
  height: 150px;
}
@media (min-height: 500px) and (max-height: 790px) {
  .open-locker-page .main-container .main .lockerBlock .lockerSubBlock .locker-open-icon {
    width: 120px;
    height: 120px;
  }
}
.open-locker-page .main-container .main .lockerBlock .lockerNumber {
  color: #FF6464;
  font-family: "SFProText-Semibold";
  font-size: 18px;
  text-align: center;
}
.open-locker-page .main-container .main .lockerBlock .lockerPrefix {
  color: #84799A;
  font-family: "SFProText-Semibold";
  font-size: 18px;
  text-align: center;
  padding-top: 15px;
}
.open-locker-page .main-container .main .closeText {
  font-family: "SFProText-Semibold";
  font-size: 1.0625rem;
  text-align: center;
  margin-bottom: 77px;
}
@media (min-height: 500px) and (max-height: 790px) {
  .open-locker-page .main-container .main .closeText {
    margin-bottom: 20px;
  }
}
.open-locker-page .main-container .main .releaseText {
  color: #1A051D;
  font-size: 15px;
  font-family: var(--primary-font-regular);
  line-height: 19px;
  padding-left: 4px;
  max-width: 250px;
  text-align: center;
  margin-bottom: 50px;
  margin-top: -50px;
}
.open-locker-page .main-container .main .btngroup {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}
.open-locker-page .main-container .main .connectivityContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 200px;
}
.open-locker-page .main-container .main .connectivityContainer .sorryText {
  margin-top: 20px;
}
.open-locker-page .main-container .main .connectivityContainer .connectivityFailureText {
  margin-top: 10px;
}

.home-screen-page {
  height: 100vh;
}
@media (max-width: 320px) {
  .home-screen-page {
    height: 115vh;
  }
}
.home-screen-page .main-container .main .tabBarStyle .MuiTabs-flexContainer {
  display: flex;
  justify-content: space-evenly;
}
.home-screen-page .main-container .main .tabBarStyle .tabBarLabel {
  width: 50%;
  font-size: 0.8125rem;
  font-family: var(--primary-font-medium);
  line-height: 15px;
  color: #808080;
}
.home-screen-page .main-container .main .tabBarStyle .tabBarLabel.single-tab {
  width: 100%;
}
.home-screen-page .main-container .main .tabBarStyle .tabBarLabel.Mui-selected {
  color: #92C23E;
}
.home-screen-page .main-container .main .tabBarStyle .favoriteTabLabel {
  color: #808080;
}
.home-screen-page .main-container .main .tabBarStyle .indicatorStyle {
  background-color: #92C23E;
}
.home-screen-page .main-container .main .tabBarStyle .indicatorStyle.indicator-full-width {
  width: 100% !important;
  left: 0 !important;
}
.home-screen-page .main-container .main .homescreenTabs {
  margin: auto;
  width: 95%;
}

.open-parcel-page {
  height: 100vh;
}
@media (max-width: 320px) {
  .open-parcel-page {
    height: 115vh;
  }
}
.open-parcel-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.open-parcel-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.open-parcel-page .main-container .main .lockerContainer {
  background-color: #FFFFFF;
  padding: 10px;
  width: 100%;
}
.open-parcel-page .main-container .main .lockerContainer .lockerInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.open-parcel-page .main-container .main .lockerContainer .lockerInfo .profileImage {
  height: 80px;
  width: 80px;
  border-radius: 8px;
}
.open-parcel-page .main-container .main .lockerContainer .lockerInfo .parcelDetails {
  padding-left: 20px;
}
.open-parcel-page .main-container .main .lockerContainer .lockerInfo .parcelDetails .lockerTitle {
  font-size: 15px;
  font-family: var(--primary-font-medium);
  line-height: 18px;
}
.open-parcel-page .main-container .main .lockerContainer .lockerInfo .parcelDetails .descText {
  color: #84799A;
  font-size: 13px;
  font-family: var(--primary-font-regular);
}
.open-parcel-page .main-container .main .lockerContainer .lockerInfo .parcelDetails .lockerText {
  font-size: 11px;
  font-family: var(--primary-font-regular);
}
.open-parcel-page .main-container .main .lockerContainer .dividerMainContainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.open-parcel-page .main-container .main .lockerContainer .dividerMainContainer .horizontalLine {
  flex: 1;
  height: 1px;
  background-color: #D0C9D6;
}
.open-parcel-page .main-container .main .lockerContainer .dividerMainContainer .textContainer {
  display: flex;
  align-items: center;
}
.open-parcel-page .main-container .main .lockerContainer .dividerMainContainer .textContainer .toText {
  font-size: 17px;
  color: #D0C9D6;
  font-family: var(--primary-font-semibold);
  margin: 0 10px;
}
.open-parcel-page .main-container .main .lockerContainer .userBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 14px;
  padding: 10px 6px;
  width: 100%;
  margin-top: 6px;
}
.open-parcel-page .main-container .main .lockerContainer .userBlock .userInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.open-parcel-page .main-container .main .lockerContainer .userBlock .userInfo .userView {
  width: 52px;
  height: 52px;
  background-color: #DFF8B1;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.open-parcel-page .main-container .main .lockerContainer .userBlock .userInfo .userView .userTitle {
  color: #1A051D;
  font-size: 22px;
  font-family: var(--secondary-font-semibold);
  text-align: center;
  line-height: 26px;
  text-transform: uppercase;
}
.open-parcel-page .main-container .main .lockerContainer .userBlock .userInfo .userDetails {
  display: flex;
  flex-direction: column;
  padding-left: 14px;
}
.open-parcel-page .main-container .main .lockerContainer .userBlock .userInfo .userDetails .info {
  color: #D0C9D6;
  font-size: 11px;
  font-family: var(--primary-font-regular);
  line-height: 15px;
  max-width: 245px;
}
.open-parcel-page .main-container .main .lockerContainer .userBlock .userInfo .userDetails .name {
  color: #1A051D;
  font-size: 15px;
  font-family: var(--primary-font-medium);
  line-height: 20px;
}
.open-parcel-page .main-container .main .lockerDetails {
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
}
.open-parcel-page .main-container .main .lockerDetails .lockerPrefix {
  color: #84799A;
  font-family: "SFProText-Semibold";
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  padding-top: 22px;
}
.open-parcel-page .main-container .main .lockerDetails .lockerNumber {
  color: #FF6464;
  font-family: "SFProText-Semibold";
  font-size: 18px;
  text-align: center;
}
.open-parcel-page .main-container .main .closeText {
  color: #1A051D;
  font-family: "SFProText-Semibold";
  font-size: 17px;
  text-align: center;
  padding-bottom: 30px;
}
.open-parcel-page .main-container .main .buttonContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 100%;
  align-items: center;
}

.custom-dialog-paper .assign-text {
  font-size: 18px;
  font-family: "SegoeUI-Bold";
  color: #1A051D;
  padding-top: 24px;
  padding-bottom: 12px;
}
.custom-dialog-paper .assign-para {
  font-size: 16px;
  font-family: "SegoeUI";
  color: #808080;
}
.custom-dialog-paper .MuiDialogContent-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.custom-dialog-paper .MuiDialogContent-root .MuiTypography-body1 {
  margin-top: 12px;
  text-align: center;
}
.custom-dialog-paper .MuiDialogActions-root {
  display: flex;
  justify-content: center;
  padding-top: 12px;
}

.swipeBtn {
  padding-left: 36px;
}
.swipeBtn .swipeText {
  color: #FFFFFF;
  font-size: 1rem;
  font-family: var(--primary-font-bold);
  line-height: 20px;
}
.swipeBtn .lockerText {
  color: #FFFFFF;
  font-size: 0.6875rem;
  font-family: var(--primary-font-medium);
  line-height: 15px;
}

.slide-button {
  border: 1px solid var(--slider-background-color);
  border-radius: var(--slider-border-radius);
  background-color: var(--slider-background-color);
  height: 75px;
}
.slide-button .slide-button-overlay {
  border-radius: 5px;
  background-color: var(--slider-overlay-bg-color) !important;
}
.slide-button .slide-button-overlay .slide-button-caret {
  border-radius: 5px;
  background-color: var(--slider-caret-bg-color) !important;
}
.slide-button .slide-button-overlay .slide-button-caret #chevrons-right {
  width: 2em;
  fill: var(--slider-caret-color);
}

.container {
  color: var(--input-placeholder-color) !important;
  font-weight: var(--input-placeholder-font-weight);
  font-size: 14px;
  font-family: var(--primary-font-regular);
  width: 100%;
}
.container .phoneInput {
  color: var(--input-text-color);
  box-shadow: 0px 7px 10px -5px rgba(0, 0, 0, 0.32);
  background-color: var(--input-background-image);
  border-radius: 6px;
  font-family: var(--primary-font-regular);
  font-size: 0.9375rem;
  display: inline-block;
  border: var(--input-border-color);
  width: 100%;
}
.container .phoneInput.error-border {
  border-color: #EE5A52;
}
.container .phoneInput .dropdown {
  width: 100%;
}

.inputContainer {
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.inputContainer .emailInput {
  color: #1A051D;
  box-shadow: 0px 7px 10px -5px rgba(0, 0, 0, 0.32);
  background-color: var(--btn-blurred-bg-image);
  border-radius: 6px;
  border: 1px solid #FFFFFF;
  font-family: var(--primary-font-regular);
  font-size: 15px;
  display: inline-block;
}
.inputContainer .button {
  height: 30px;
  position: absolute;
  right: 3px;
  top: 42px;
  justify-content: center;
  color: #92C23E;
  text-transform: none;
}

.input-container {
  background-color: #FFFFFF;
  color: #1A051D;
  border-radius: var(--input-border-radius);
}
.input-container .placeholder-color {
  color: var(--input-placeholder-color) !important;
  font-weight: var(--input-placeholder-font-weight);
  font-size: 14px;
  font-family: var(--primary-font-regular);
}

.reason-validation-error {
  margin: 10px 0 0 15px;
  font-family: var(--primary-font-regular);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #EE5A52;
}

.list-item {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 8px;
}
.list-item .initial-view {
  border-radius: 16px;
  padding: 10px;
  margin-right: 8px;
  width: 52px;
  height: 52px;
}
.list-item .favourite-view {
  position: absolute;
  top: 20px;
  right: 14px;
}
.list-item .title-view {
  margin-right: 30px;
}
.list-item .title-view .item-title-text {
  margin-left: 5px;
  font-size: 1.25rem;
  font-family: var(--primary-font-regular);
  color: #1A051D;
}
@media (max-width: 360px) {
  .list-item .title-view .item-title-text {
    font-size: 1rem;
  }
}

.locker-list .locker-content {
  background-color: #FFFFFF;
  padding: 18px 23px;
  border-radius: 14px;
  margin-bottom: 4px;
}
.locker-list .locker-content .locker-number {
  color: #1A051D;
  font-size: 15px;
  line-height: 20px;
  font-family: var(--primary-font-medium);
}

.custom-box-input {
  display: flex;
  justify-content: space-evenly;
}
.custom-box-input .placeholder-color {
  color: var(--input-placeholder-color) !important;
  font-weight: var(--input-placeholder-font-weight);
  font-size: 14px;
  font-family: var(--primary-font-regular);
  font-size: 0.9375rem;
}

.search-input {
  width: 100%;
}
.search-input .MuiOutlinedInput-input {
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}

.badge-container {
  display: flex;
  flex-direction: column;
}
.badge-container .content-view {
  display: flex;
  flex-direction: row;
}
.badge-container .content-view .badge {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #F39590;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
.badge-container .content-view .badge .badge-content {
  font-family: var(--primary-font-medium);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
}
.badge-container .content-view .primary-text {
  font-family: var(--primary-font-medium);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  margin-bottom: 2px;
  margin-top: 4px;
}
.badge-container .content-view .secondary-text {
  font-family: var(--primary-font-regular);
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 12.1px;
  text-align: left;
}

.qr-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.qr-container .qr-code-reader {
  display: flex;
  position: relative;
  justify-content: center;
}
.qr-container .camera-frame-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  color: #FFFFFF;
  text-align: center;
  margin-top: 10px;
  padding: 2px;
}
.qr-container .custom-finder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 200px;
  width: 200px;
}

.equipment-grid-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .equipment-grid-page {
    height: 115vh;
  }
}
.equipment-grid-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.equipment-grid-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.equipment-grid-page .main-container .main .welcome-heading {
  align-self: flex-start;
  color: var(--primary-text-color);
  font-size: 24px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
.equipment-grid-page .main-container .main .choose-and-rent-text {
  margin-top: 4px;
  color: var(--tertiary-text-color);
  font-size: 16;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
.equipment-grid-page .main-container .main .equipment-category {
  width: 100%;
  margin-top: 24px;
}
.equipment-grid-page .main-container .main .equipment-category .category-title {
  color: #1A051D;
  font-size: 20px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
.equipment-grid-page .main-container .main .no-product-warning-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.equipment-grid-page .main-container .main .no-product-warning-container .no-product-warning-text {
  font-size: 1.125rem;
  font-family: var(--primary-font-regular);
  padding-top: 57px;
  padding-bottom: 13px;
}

@media (max-width: 420px) {
  .equipment-grid-page .main-container .main {
    padding: 40px 16px;
  }
  .equipment-grid-page .main-container .main .choose-and-rent-text {
    color: var(--tertiary-text-color);
    font-size: 14px;
    font-family: var(--primary-font-regular);
    font-weight: 400;
  }
}
.equipment-details-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .equipment-details-page {
    height: 115vh;
  }
}
.equipment-details-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.equipment-details-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding-bottom: 80px;
}
.equipment-details-page .main-container .main .title-and-description-container .title {
  color: var(--primary-text-color);
  font-size: 20px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
  text-align: center;
}
.equipment-details-page .main-container .main .title-and-description-container .description {
  margin-top: 2px;
  color: var(--tertiary-text-color);
  font-size: 14px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
  text-align: center;
}
.equipment-details-page .main-container .main .equipment-images {
  width: 100%;
  margin: 30px 0px;
}
.equipment-details-page .main-container .main .equipment-images .swiper.image-carousel {
  width: 100%;
}
.equipment-details-page .main-container .main .equipment-images .swiper.image-carousel .swiper-wrapper .swiper-slide.image-slide {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.equipment-details-page .main-container .main .equipment-images .swiper.image-carousel .swiper-wrapper .swiper-slide.image-slide .equipment-image {
  width: 100%;
  max-width: 280px;
  height: auto;
  border-radius: 10px;
  background-color: #F4F2F8;
}
.equipment-details-page .main-container .main .equipment-images .swiper.image-carousel .swiper-pagination {
  margin-top: 10px;
  position: static !important;
}
.equipment-details-page .main-container .main .equipment-images .swiper.image-carousel .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #3F3356;
}
.equipment-details-page .main-container .main .pricing-info {
  align-self: flex-start;
}
.equipment-details-page .main-container .main .pricing-info .min-price-in-words {
  color: var(--primary-text-color);
  font-size: 18px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
.equipment-details-page .main-container .main .pricing-info .max-price-in-words {
  color: #1A051D;
  font-size: 14px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
.equipment-details-page .main-container .main .pricing-info .pricing-info-button {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.equipment-details-page .main-container .main .pricing-info .pricing-info-button .pricing-info {
  color: #1A051D;
  text-decoration: underline;
  cursor: pointer;
  color: var(--primary-text-color);
  font-size: 16px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
.equipment-details-page .main-container .main .pricing-and-equipment-info-divider {
  width: 100%;
  margin: 30px 0px;
}
.equipment-details-page .main-container .main .equipment-includes-info {
  width: 100%;
}
.equipment-details-page .main-container .main .equipment-includes-info .title {
  color: var(--primary-text-color);
  font-size: 18px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
.equipment-details-page .main-container .main .equipment-includes-info .images-outer-container {
  width: 100%;
  overflow-x: scroll;
}
.equipment-details-page .main-container .main .equipment-includes-info .images-outer-container .images-inner-container {
  padding-bottom: 8px;
  margin-top: 20px;
  display: flex;
}
.equipment-details-page .main-container .main .equipment-includes-info .images-outer-container .images-inner-container .image-wrapper {
  background-color: #F4F2F8;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.equipment-details-page .main-container .main .equipment-includes-info .images-outer-container .images-inner-container .image-wrapper .equipment-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.equipment-details-page .main-container .main .help-instructions {
  width: 100%;
  margin: 40px 0px;
}
.equipment-details-page .main-container .main .help-instructions .title {
  color: var(--primary-text-color);
  font-size: 18px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
.equipment-details-page .main-container .main .help-instructions .instructions {
  width: 100%;
  margin-top: 10px;
  color: var(--tertiary-text-color);
  font-size: 14px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
  overflow-x: hidden;
}
.equipment-details-page .main-container .main .help-instructions .instructions h2 {
  color: var(--tertiary-text-color);
  font-size: 16px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
.equipment-details-page .main-container .main .help-instructions .instructions ul {
  width: 100%;
  padding-left: 20px;
}
.equipment-details-page .main-container .main .rent-button-container, .equipment-details-page .main-container .main .notify-button-container {
  width: 100%;
  max-width: 480px;
  padding: 16px;
  background-color: #F3F3F3;
  box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.1019607843);
  position: fixed;
  bottom: 0px;
}
.equipment-details-page .main-container .main .rent-button-container .rent-now-button, .equipment-details-page .main-container .main .notify-button-container .rent-now-button {
  width: 100%;
  color: var(--secondary-text-color);
  font-size: 20px;
  font-family: var(--secondary-font-bold);
  font-weight: 400;
  text-transform: capitalize;
  z-index: 100;
}
.equipment-details-page .main-container .main .rent-button-container .notify-me-button, .equipment-details-page .main-container .main .notify-button-container .notify-me-button {
  width: 100%;
  color: var(--secondary-text-color);
  font-size: 20px;
  font-family: var(--secondary-font-bold);
  font-weight: 400;
  text-transform: capitalize;
  z-index: 100;
}
body .pricing-info-modal-container {
  width: 100%;
  max-width: 480px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: nowrap;
}
body .pricing-info-modal-container .pricing-info-modal-content {
  width: 100%;
  padding: 20px;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  background-color: white;
  outline: unset;
  animation: slide-up 0.2s ease-out;
}
@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
body .pricing-info-modal-container .pricing-info-modal-content .title-and-close-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
body .pricing-info-modal-container .pricing-info-modal-content .title-and-close-button .pricing-info-title {
  color: var(--primary-text-color);
  font-size: 18px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
body .pricing-info-modal-container .pricing-info-modal-content .pricing-help {
  margin: 20px 0;
  color: var(--tertiary-text-color);
  font-size: 14px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
body .pricing-info-modal-container .pricing-info-modal-content .pricing-help h2 {
  color: var(--primary-text-color);
  font-size: 16px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
body .pricing-info-modal-container .pricing-info-modal-content .pricing-info {
  margin-top: 20px;
}
body .pricing-info-modal-container .pricing-info-modal-content .pricing-info .title {
  color: var(--primary-text-color);
  font-size: 16px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
body .pricing-info-modal-container .pricing-info-modal-content .pricing-info .equipment-price {
  margin-top: 10px;
  color: var(--tertiary-text-color);
  font-size: 14px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
body .card-info-modal-container {
  width: 100%;
  max-width: 480px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: nowrap;
}
body .card-info-modal-container .card-info-modal-content {
  width: 100%;
  padding: 20px;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  background-color: white;
  outline: unset;
  animation: slide-up 0.2s ease-out;
}
@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
body .card-info-modal-container .card-info-modal-content .title-and-close-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
body .card-info-modal-container .card-info-modal-content .title-and-close-button .card-info-title {
  color: var(--primary-text-color);
  font-size: 18px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
body .card-info-modal-container .card-info-modal-content .card-help {
  margin: 20px 0;
  color: var(--tertiary-text-color);
  font-size: 14px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
body .card-info-modal-container .card-info-modal-content .card-help h2 {
  color: var(--primary-text-color);
  font-size: 16px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
body .card-info-modal-container .card-info-modal-content .card-info {
  margin-top: 20px;
}
body .card-info-modal-container .card-info-modal-content .card-info .title {
  color: var(--primary-text-color);
  font-size: 16px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
body .card-info-modal-container .card-info-modal-content .card-number-input .card-placeholder {
  color: "#000000" !important;
  font-weight: var(--input-placeholder-font-weight);
  font-size: 14px;
  font-family: var(--primary-font-regular);
}
body .card-info-modal-container .card-info-modal-content .cvv-and-expiry-input-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
  margin-top: 20px;
}

.equipment-card {
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  background-color: transparent !important;
  box-shadow: none !important;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}
.equipment-card .card-content {
  padding: 0px !important;
}
.equipment-card .card-content.content-col-1 {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.equipment-card .card-content.content-col-1 .equipment-image {
  min-width: 100px;
  padding: 10px;
  border-radius: 8px;
  background-color: #F4F2F8;
}
.equipment-card .card-content.content-col-2 {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.equipment-card .card-content.content-col-2 .equipment-title {
  margin-top: 4px;
  color: #1A051D;
  font-size: 14px;
  font-family: var(--primary-font-medium);
  font-weight: 400;
}
.equipment-card .card-content.content-col-2 .equipment-description {
  margin-top: 2px;
  color: var(--tertiary-text-color);
  font-size: 12px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
  max-height: 40;
  overflow: hidden;
}
.equipment-card .card-content.content-col-2 .timer-indicator-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.equipment-card .card-content.content-col-2 .timer-indicator-container .timer-indicator {
  padding: 10px 22px;
  background-color: #F7F7F7;
  border-radius: 100px;
}
.equipment-card .card-content.content-col-2 .timer-indicator-container .timer-indicator .timer-text {
  color: var(--primary-text-color);
  font-size: 10px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
.equipment-card .card-content.content-col-2 .availability-indicator-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.equipment-card .card-content.content-col-2 .availability-indicator-container .availability-indicator {
  padding: 4px 16px;
  border-radius: 9999px;
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.equipment-card .card-content.content-col-2 .availability-indicator-container .availability-indicator.available {
  background-color: #D6FFD8;
}
.equipment-card .card-content.content-col-2 .availability-indicator-container .availability-indicator.available .circle-icon {
  color: #03A700;
  font-size: 6px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
.equipment-card .card-content.content-col-2 .availability-indicator-container .availability-indicator.available .availability-text {
  margin-left: 6px;
  color: #03A700;
  font-size: 12px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
.equipment-card .card-content.content-col-2 .availability-indicator-container .availability-indicator.not-available {
  background-color: #FFFBD6;
  margin-right: 10px;
}
.equipment-card .card-content.content-col-2 .availability-indicator-container .availability-indicator.not-available .circle-icon {
  color: #A78200;
  font-size: 6px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
.equipment-card .card-content.content-col-2 .availability-indicator-container .availability-indicator.not-available .availability-text {
  margin-left: 6px;
  color: #A78200;
  font-size: 12px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
.equipment-card .card-content.content-col-2 .availability-indicator-container .notify-me-button {
  padding: 3px 16px;
  border-radius: 9999px;
  margin-top: 6px;
  background-color: #222222;
  color: #FFFFFF;
  font-size: 12px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
  text-transform: capitalize;
}
.equipment-card .card-content.content-col-2 .availability-indicator-container .notify-me-button .notification-icon {
  font-size: 12px;
}

@media (max-width: 400px) {
  .equipment-card .card-content.content-col-2 {
    margin-left: 10px;
  }
  .equipment-card .card-content.content-col-2 .availability-indicator-container .availability-indicator.available .availability-text, .equipment-card .card-content.content-col-2 .availability-indicator-container .availability-indicator.not-available .availability-text {
    font-size: 10px;
  }
  .equipment-card .card-content.content-col-2 .availability-indicator-container .notify-me-button {
    font-size: 10px;
  }
}
.verification-successful-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .verification-successful-page {
    height: 115vh;
  }
}
.verification-successful-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.verification-successful-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding-bottom: 80px;
}
.verification-successful-page .main-container .main .img-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.verification-successful-page .main-container .main .img-container .verification-successful-img {
  width: 100%;
  max-width: 200px;
  height: auto;
}
.verification-successful-page .main-container .main .title-and-description-container {
  margin-top: 1em;
}
.verification-successful-page .main-container .main .title-and-description-container .title {
  color: var(--primary-text-color);
  font-size: 24px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
  text-align: center;
}
.verification-successful-page .main-container .main .title-and-description-container .description {
  margin-top: 2px;
  color: var(--tertiary-text-color);
  font-size: 14px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
  text-align: center;
}
.verification-successful-page .main-container .main #verification-title-and-rental-info-divider {
  width: 100%;
  margin: 30px 0 20px 0;
}
.verification-successful-page .main-container .main .product-rental-info-container {
  width: 100%;
  margin-bottom: 2em;
}
.verification-successful-page .main-container .main .product-rental-info-container .info-container {
  width: 90%;
  margin: 16px auto 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.verification-successful-page .main-container .main .product-rental-info-container .info-container .icon-container {
  width: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.verification-successful-page .main-container .main .product-rental-info-container .info-container .icon-container .product-info-icon {
  font-size: 2em;
  color: #1A051D;
}
.verification-successful-page .main-container .main .product-rental-info-container .info-container .title-and-value-container .title {
  color: var(--tertiary-text-color);
  font-size: 12px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
.verification-successful-page .main-container .main .product-rental-info-container .info-container .title-and-value-container .value {
  color: var(--primary-text-color);
  font-size: 16px;
  font-family: var(--primary-font-medium);
  font-weight: 400;
}
.verification-successful-page .main-container .main .slide-to-open-button-container {
  width: 100%;
  max-width: 480px;
  padding: 16px;
  background-color: #F4F2F8;
  box-shadow: 0px -5px 8px #D3D3D3;
  position: fixed;
  bottom: 0px;
}
.verification-successful-page .main-container .main .slide-to-open-button-container .slide-to-open-button {
  width: 100%;
  height: 56px;
  padding: 10px;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 20px;
  font-family: "SFProText-Bold";
  font-weight: 400;
  text-transform: capitalize;
}

.unit-opened-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .unit-opened-page {
    height: 115vh;
  }
}
.unit-opened-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.unit-opened-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding-top: 0px;
  padding-bottom: 80px;
}
.unit-opened-page .main-container .main .img-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.unit-opened-page .main-container .main .img-container .unit-opened-img {
  width: 100%;
  max-width: 200px;
  height: auto;
}
.unit-opened-page .main-container .main .title-and-description-container {
  margin-top: 1em;
}
.unit-opened-page .main-container .main .title-and-description-container .title {
  color: var(--primary-text-color);
  font-size: 24px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
  text-align: center;
}
.unit-opened-page .main-container .main .title-and-description-container .description {
  margin-top: 2px;
  color: var(--tertiary-text-color);
  font-size: 14px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
  text-align: center;
}
.unit-opened-page .main-container .main .remaining-time-container {
  margin: 3em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.unit-opened-page .main-container .main .remaining-time-container .time-unit-box {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 1px #D3D3D3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.unit-opened-page .main-container .main .remaining-time-container .time-unit-box .digits {
  color: var(--primary-text-color);
  font-size: 24px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
.unit-opened-page .main-container .main .remaining-time-container .time-unit-box .time-unit {
  color: var(--primary-text-color);
  font-size: 14px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
.unit-opened-page .main-container .main .remaining-time-container .separating-colon {
  padding: 0 8px;
  color: var(--primary-text-color);
  font-size: 24px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
.unit-opened-page .main-container .main .buttons-container {
  width: 100%;
  margin-bottom: 3em;
}
.unit-opened-page .main-container .main .buttons-container #rented-products-button, .unit-opened-page .main-container .main .buttons-container #rent-new-product-button {
  width: 100%;
  padding: 10px;
  border: 1px solid #1A051D;
  border-radius: 8px;
  margin-bottom: 12px;
  color: var(--primary-text-color);
  font-size: 20px;
  font-family: var(--secondary-font-bold);
  font-weight: 400;
  display: block;
  text-transform: capitalize;
}
.unit-opened-page .main-container .main .end-rental-and-return-button-container {
  width: 100%;
  max-width: 480px;
  padding: 16px;
  background-color: #F4F2F8;
  box-shadow: 0px -5px 8px #D3D3D3;
  position: fixed;
  bottom: 0px;
}
.unit-opened-page .main-container .main .end-rental-and-return-button-container .end-rental-and-return-button {
  width: 100%;
  height: 56px;
  padding: 10px;
  color: var(--secondary-text-color);
  font-size: 20px;
  font-family: var(--secondary-font-bold);
  font-weight: 400;
  text-transform: capitalize;
}

@media (max-height: 720px) {
  .unit-opened-page .main-container .main .img-container .unit-opened-img {
    max-width: 170px;
  }
  .unit-opened-page .main-container .main .title-and-description-container {
    margin-top: 0.5em;
  }
  .unit-opened-page .main-container .main .remaining-time-container {
    margin: 2em 0;
  }
}
.payment-successful-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .payment-successful-page {
    height: 115vh;
  }
}
.payment-successful-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.payment-successful-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding-bottom: 80px;
}
.payment-successful-page .main-container .main .img-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.payment-successful-page .main-container .main .img-container .payment-successful-img {
  width: 100%;
  max-width: 200px;
  height: auto;
}
.payment-successful-page .main-container .main .title-and-description-container {
  margin-top: 1em;
}
.payment-successful-page .main-container .main .title-and-description-container .title {
  color: var(--primary-text-color);
  font-size: 20px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
  text-align: center;
}
.payment-successful-page .main-container .main .title-and-description-container .description {
  margin-top: 2px;
  color: #808080;
  font-size: 14px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
  text-align: center;
}
.payment-successful-page .main-container .main #payment-title-and-rental-info-divider {
  width: 100%;
  margin: 30px 0 20px 0;
}
.payment-successful-page .main-container .main .product-rental-info-container {
  width: 100%;
  margin-bottom: 2em;
}
.payment-successful-page .main-container .main .product-rental-info-container .info-container {
  width: 90%;
  margin: 16px auto 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.payment-successful-page .main-container .main .product-rental-info-container .info-container .icon-container {
  width: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.payment-successful-page .main-container .main .product-rental-info-container .info-container .icon-container .product-info-icon {
  font-size: 2em;
  color: #1A051D;
}
.payment-successful-page .main-container .main .product-rental-info-container .info-container .title-and-value-container .title {
  color: var(--tertiary-text-color);
  font-size: 14px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
.payment-successful-page .main-container .main .product-rental-info-container .info-container .title-and-value-container .value {
  color: var(--primary-text-color);
  font-size: 16px;
  font-family: var(--primary-font-semibold);
  font-weight: 500;
}
.payment-successful-page .main-container .main .go-to-home-button-container {
  width: 100%;
  max-width: 480px;
  padding: 16px;
  background-color: #F3F3F3;
  box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.1019607843);
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0px;
}
.payment-successful-page .main-container .main .go-to-home-button-container .go-to-home-button {
  width: 100%;
  color: var(--secondary-text-color);
  font-size: 20px;
  font-family: var(--secondary-font-bold);
  font-weight: 400;
  text-transform: capitalize;
}

.invoice-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .invoice-page {
    height: 115vh;
  }
}
.invoice-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.invoice-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.invoice-page .main-container .main .invoice-details-heading {
  color: var(--primary-text-color);
  font-size: 24px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
.invoice-page .main-container .main .invoice-details-text {
  margin-top: 4px;
  color: var(--tertiary-text-color);
  font-size: 16;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
.invoice-page .main-container .main .invoice-details {
  width: 100%;
  border: 1px solid #D3D3D3;
  border-radius: 8px;
  margin: 1.5em;
}
.invoice-page .main-container .main .invoice-details .invoice-details-section {
  width: 100%;
  padding: 1.5em;
  border-bottom: 1px solid #D3D3D3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.invoice-page .main-container .main .invoice-details .invoice-details-section.section-1 .app-logo {
  width: 48px;
  margin-bottom: 1.5em;
}
.invoice-page .main-container .main .invoice-details .invoice-details-section .invoice-details-grid-container .grid-item {
  margin-bottom: 1.5em;
}
.invoice-page .main-container .main .invoice-details .invoice-details-section .invoice-details-grid-container .grid-item:nth-last-child(2), .invoice-page .main-container .main .invoice-details .invoice-details-section .invoice-details-grid-container .grid-item:last-child {
  margin-bottom: 0px;
}
.invoice-page .main-container .main .invoice-details .invoice-details-section .invoice-details-grid-container .grid-item.right {
  padding-left: 1.5em;
}
.invoice-page .main-container .main .invoice-details .invoice-details-section.section-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.invoice-page .main-container .main .invoice-details .invoice-details-section.section-3 .social-media-icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 0.5em;
  background-color: #D3D3D3;
}
.invoice-page .main-container .main .invoice-details .invoice-details-section.section-3 .social-media-icon:last-child {
  margin-right: 0px;
}
.invoice-page .main-container .main .invoice-details .invoice-details-section.section-4 {
  border-bottom: 0px;
}
.invoice-page .main-container .main .invoice-details .invoice-details-section.section-4 .thank-you-heading {
  color: var(--primary-text-color);
  font-size: 24px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
.invoice-page .main-container .main .invoice-details .invoice-details-section.section-4 .company-name {
  color: var(--tertiary-text-color);
  font-size: 16;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}

.my-rented-products-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .my-rented-products-page {
    height: 115vh;
  }
}
.my-rented-products-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-rented-products-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  padding: 30px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.my-rented-products-page .main-container .main .title-container {
  margin-top: 1em;
}
.my-rented-products-page .main-container .main .title-container .title {
  color: var(--primary-text-color);
  font-size: 18px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
  text-align: center;
}
.my-rented-products-page .main-container .main .equipment-category {
  display: flex;
  flex-direction: row;
}
.my-rented-products-page .main-container .main .equipment-category .radio-button {
  color: var(--primary-text-color);
}
.my-rented-products-page .main-container .main .end-rental-and-rent-new-product-container {
  width: 100%;
  max-width: 480px;
  padding: 16px;
  background-color: #F3F3F3;
  box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.1019607843);
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0px;
}
.my-rented-products-page .main-container .main .end-rental-and-rent-new-product-container .end-rental-and-return-button {
  border: 1px solid var(--btn-outlined-border-color);
  width: 100%;
  padding: 10px;
  color: var(--primary-text-color);
  font-size: 20px;
  font-family: var(--secondary-font-bold);
  font-weight: 400;
  text-transform: capitalize;
}
.my-rented-products-page .main-container .main .end-rental-and-rent-new-product-container .rent-new-product-button {
  width: 100%;
  color: var(--secondary-text-color);
  font-size: 20px;
  font-family: var(--secondary-font-bold);
  font-weight: 400;
  text-transform: capitalize;
}

.take-product-photo-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .take-product-photo-page {
    height: 115vh;
  }
}
.take-product-photo-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.take-product-photo-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.take-product-photo-page .main-container .main .video-container {
  position: relative;
  width: 100%;
  max-width: 100%;
}
.take-product-photo-page .main-container .main .switch-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}
.take-product-photo-page .main-container .main .button-row {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  /* Space between buttons */
}
.take-product-photo-page .main-container .main .button-row .capture-button,
.take-product-photo-page .main-container .main .button-row .close-button {
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}
.take-product-photo-page .main-container .main .button-row .capture-button.close,
.take-product-photo-page .main-container .main .button-row .close-button.close {
  background-color: #D7746F;
}
.take-product-photo-page .main-container .main .img-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}
.take-product-photo-page .main-container .main .img-container .take-photo-img {
  width: 100%;
  max-width: 290px;
  height: auto;
}
.take-product-photo-page .main-container .main .take-and-retake-btn-container {
  width: 100%;
  margin-bottom: 30px;
}
.take-product-photo-page .main-container .main .take-and-retake-btn-container .take-photo-button {
  width: 100%;
  color: var(--secondary-text-color);
  font-size: 20px;
  font-family: var(--secondary-font-bold);
  font-weight: 400;
  text-transform: capitalize;
}
.take-product-photo-page .main-container .main .take-and-retake-btn-container .retake-photo-button {
  width: 100%;
  border: 1px solid var(--btn-outlined-border-color);
  padding: 10px;
  color: var(--primary-text-color);
  font-size: 20px;
  font-family: var(--secondary-font-bold);
  font-weight: 400;
  text-transform: capitalize;
}
.take-product-photo-page .main-container .main .pricing-info {
  align-self: flex-start;
}
.take-product-photo-page .main-container .main .pricing-info .min-price-in-words {
  color: var(--primary-text-color);
  font-size: 20px;
  font-family: var(--secondary-font-bold);
  font-weight: 400;
}
.take-product-photo-page .main-container .main .pricing-info .max-price-in-words {
  color: var(--primary-text-color);
  font-size: 16px;
  font-family: var(--secondary-font-semibold);
  font-weight: 400;
}
.take-product-photo-page .main-container .main .pricing-info .pricing-info-button {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.take-product-photo-page .main-container .main .pricing-info .pricing-info-button .pricing-info {
  color: #1A051D;
  text-decoration: underline;
  cursor: pointer;
  color: var(--primary-text-color);
  font-size: 16px;
  font-family: var(--secondary-font-bold);
  font-weight: 400;
}
.take-product-photo-page .main-container .main .pay-now-button-container {
  width: 100%;
  max-width: 480px;
  padding: 16px;
  background-color: #F3F3F3;
  position: fixed;
  bottom: 0px;
  box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.1019607843);
}
.take-product-photo-page .main-container .main .pay-now-button-container .pay-now-button {
  width: 100%;
  padding: 10px;
  color: var(--secondary-text-color);
  font-size: 20px;
  font-family: var(--secondary-font-bold);
  font-weight: 400;
  text-transform: capitalize;
}

.account-details-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .account-details-page {
    height: 115vh;
  }
}
.account-details-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.account-details-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.account-details-page .main-container .main .round-and-initial-container {
  display: flex;
}
.account-details-page .main-container .main .round-shape {
  width: 80px;
  height: 80px;
  background-color: #F7F7F7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account-details-page .main-container .main .initial {
  color: var(--primary-text-color);
  font-size: 24px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
.account-details-page .main-container .main .card-details-container {
  width: 100%;
  margin-top: 30px;
}
.account-details-page .main-container .main .card-details-container .title-and-close-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.account-details-page .main-container .main .card-details-container .title-and-close-button .pricing-info-title {
  color: var(--primary-text-color);
  font-size: 20px;
  font-family: var(--primary-font-bold);
  font-weight: 400;
}
.account-details-page .main-container .main .card-details-container .card-details-text {
  color: var(--tertiary-text-color);
  font-size: 14px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
  text-align: left;
}
.account-details-page .main-container .main .card-input-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.account-details-page .main-container .main .card-input-container .card-number-input {
  border: 1px solid #222222;
}
.account-details-page .main-container .main .card-input-container .card-number-input .card-placeholder {
  color: "#000000" !important;
  font-weight: var(--input-placeholder-font-weight);
  font-size: 14px;
  font-family: var(--primary-font-regular);
}
.account-details-page .main-container .main .card-input-container .cvv-and-expiry-input-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}
.account-details-page .main-container .main .edit-card-details-container {
  width: 100%;
  max-width: 480px;
  padding: 16px;
  background-color: #F3F3F3;
  box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.1019607843);
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0px;
}
.account-details-page .main-container .main .edit-card-details-container .edit-card-details-button {
  width: 100%;
  color: var(--secondary-text-color);
  font-size: 20px;
  font-family: var(--secondary-font-bold);
  font-weight: 400;
  text-transform: capitalize;
}

.input-container {
  background-color: #FFFFFF;
  color: #1A051D;
  border-radius: var(--input-border-radius);
}
.input-container .placeholder-color {
  color: var(--input-placeholder-color) !important;
  font-weight: var(--input-placeholder-font-weight);
  font-size: 14px;
  font-family: var(--primary-font-regular);
}

.reason-validation-error {
  margin: 10px 0 0 15px;
  font-family: var(--primary-font-regular);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #EE5A52;
}

.back-header .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.back-header .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  padding: 0px;
}
.back-header .main-container .main .back-header-container {
  padding: 10px 0px 0px 10px;
}

.chat-bot-page {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.chat-bot-page .chat-support-container {
  width: 100%;
  height: 100%;
  border: none;
}

.faq-page {
  width: 100%;
  height: 100%;
  position: fixed;
}
.faq-page .faq-container {
  width: 100%;
  height: 100%;
  border: none;
}

.contact-us-page {
  height: 100vh;
}
@media (max-width: 320px) {
  .contact-us-page {
    height: 115vh;
  }
}
.contact-us-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.contact-us-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.privacy_policy_page {
  height: 100vh;
}
@media (max-width: 320px) {
  .privacy_policy_page {
    height: 115vh;
  }
}
.privacy_policy_page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.privacy_policy_page .main-container .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.custom-dialog .dialog-title {
  text-align: center;
  font-family: var(--primary-font-medium);
}
.custom-dialog .dialog-content-text {
  font-size: 1rem;
  text-align: center;
  padding-bottom: 1em;
  font-family: var(--primary-font-regular);
  color: var(--primary-text-color);
}
.custom-dialog .cancel-button {
  border: 1px solid var(--btn-outlined-border-color);
  width: 100%;
  padding: 10px 35px;
  border-radius: var(--btn-border-radius);
  color: var(--primary-text-color);
  font-size: 15px;
  font-family: var(--primary-font-medium);
  font-weight: 400;
  text-transform: capitalize;
}

.spinner-loader-container {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
}
.spinner-loader-container .loader {
  --d: 22px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: var(--app-loader-color);
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0, calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 1px, calc(0 * var(--d)) calc(1 * var(--d)) 0 2px, calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 3px, calc(-1 * var(--d)) calc(0 * var(--d)) 0 4px, calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 5px, calc(0 * var(--d)) calc(-1 * var(--d)) 0 6px;
  animation: l27 1s infinite steps(8);
}
@keyframes l27 {
  100% {
    transform: rotate(1turn);
  }
}

.locker-unit-selection-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .locker-unit-selection-page {
    height: 115vh;
  }
}
.locker-unit-selection-page .main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-unit-selection-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-unit-selection-page .main-container .main .selected-locker-size {
  font-size: 1.5em;
  color: #808080;
}
.locker-unit-selection-page .main-container .main .locker-unit-list {
  width: 100%;
  margin: 2em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.locker-unit-selection-page .main-container .main .locker-unit-list .locker-unit {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: unset;
  text-align: center;
  color: var(--btn-text-variant-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
  border: 1px solid var(--btn-text-variant-color);
  margin-bottom: 2em;
  text-transform: capitalize;
}
.locker-unit-selection-page .main-container .main .locker-unit-list .locker-unit.selected-unit {
  width: 100%;
  padding: 10px;
  border-radius: var(--btn-border-radius);
  background-image: var(--btn-primary-contained-bg-image);
  text-align: center;
  color: var(--btn-primary-text-color);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
  border-width: 0;
}
.locker-unit-selection-page .main-container .main .reserve-locker-button-container {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  padding-top: 0;
  position: fixed;
  bottom: 120px;
}

.swipe-to-open-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media (max-width: 320px) {
  .swipe-to-open-page {
    height: 115vh;
  }
}
.swipe-to-open-page .main-container {
  width: 100%;
  min-height: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.swipe-to-open-page .main-container .main {
  width: 100%;
  max-width: 480px;
  padding: 40px 28px 0;
  min-height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.swipe-to-open-page .main-container .main .locker-icon-container {
  margin-bottom: 1em;
}
.swipe-to-open-page .main-container .main .locker-bank-name, .swipe-to-open-page .main-container .main .locker-unit {
  margin-bottom: 1em;
  color: var(--tertiary-text-color);
  font-size: 18px;
  font-family: var(--primary-font-regular);
  font-weight: 400;
}
.swipe-to-open-page .main-container .main .slide-to-open-button-container {
  width: 100%;
}
.swipe-to-open-page .main-container .main .slide-to-open-button-container .slide-button {
  height: 3.5em;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/Segoe UI.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Segoe UI Bold";
  src: url("../fonts/Segoe UI Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "SFProDisplay-Bold";
  src: url("../fonts/SFProDisplay-Bold.otf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "SFProDisplay-Semibold";
  src: url("../fonts/SFProDisplay-Semibold.otf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "SFProText-Bold";
  src: url("../fonts/SFProText-Bold.otf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "SFProText-Medium";
  src: url("../fonts/SFProText-Medium.otf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "SFProText-Regular";
  src: url("../fonts/SFProText-Regular.otf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "SFProText-Semibold";
  src: url("../fonts/SFProText-Semibold.otf");
  font-weight: bold;
  font-style: normal;
}
body {
  background-color: white;
}

.app {
  background-color: var(--app-background-color);
  max-width: 480px;
  margin: 0 auto;
}
.app.dark-app {
  background-color: var(--app-dark-background-color);
}
